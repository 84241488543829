import React from "react";
import Slider from "react-slick";
import Images1 from './images/2.jpg';
import Images2 from './images/34.95.jpg';
import Images3 from './images/1.jpg';
import Images4 from './images/3.jpg';
import Images5 from './images/4.jpg';
import SectionTitle from '../../components/SectionTitle';
import './AutoPlaySlider.css'; // For custom styles

function AutoPlay() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,  // Faster transitions
    autoplaySpeed: 4000,
    cssEase: "cubic-bezier(0.68, -0.55, 0.27, 1.55)", // Smooth bounce effect
    pauseOnHover: true,  // Pauses autoplay on hover
    arrows: false,  // Disable arrows for a cleaner look
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,  // Display only 1 image at a time on smaller screens
        }
      }
    ]
  };

  return (
    <div className="slider-container">
      <SectionTitle Title="#2025 Placements" />
      
      <Slider {...settings}>
        {[Images1, Images2, Images3, Images4,Images5].map((image, index) => (
          <div key={index} className="image-slide col-md-4">
            <img src={image} alt={`Slide ${index + 1}`} className="slider-image" />
          </div>
        ))}
      </Slider>
      <br/>
    </div>
  );
}

export default AutoPlay;
