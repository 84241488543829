import React from 'react';
import { Link } from 'react-router-dom';

// Image
import aboutImg from '../../assets/images/about/ab3.png';
import placements from '../../assets/images/about/Placements.jpg';
import infrastructure from '../../assets/images/about/Infrastructure.jpg';
import faculty from '../../assets/images/about/Faculty.jpg';
import academics from '../../assets/images/about/Academics.jpg';
import amenties from '../../assets/images/about/Amenities.jpg';
import point from '../../assets/images/icons/image.png';


import shapeImg1 from '../../assets/images/about/shape_02.png';

const WhyADITYApart = () => {

    return (
        <div class="about__area about2__area about3__area p-relative pb---60" style={{ marginTop: "-43px" }}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img class="react__shape__1" src={shapeImg1} alt="Shape Image" /> */}
                            <img src={placements} alt="About" width="100%" style={{marginTop:"100px"}} />
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about__content">
                            <div>
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Placements</h2>
                                <ul>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> 100% placements for past 19 years for all registered and eligible students.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> 2500+ Placement offers from 250+ Industry-connect Companies, in 2023-24 academic year, with Rs.33.54 Lakhs per annum as highest pay package and Rs.5.26 Lakhs per annum as average pay package.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Full semester Industry-Oriented project to ensure all students work in industry prior to graduation.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Exclusive focus on placement training and pre-placement offers.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Internships in globally acclaimed MNCs to hone futuristic technical skills in the students.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Foreign Language Learning via Centre for Foreign Languages to improve International Placements.</li>
                                </ul>
                            </div>
                            {/* <div class="about__btn wow animate__fadeInUp" data-wow-duration="0.7s">
                                <Link to="/about"> Read More </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-lg-6">
                        <div class="about__content">
                            <div>
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Faculty</h2>
                                <ul>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> 300+ faculty with rich industry, academia, and research experience.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> 100+ Doctoral faculty in varied research domains.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> 1000+ Paper Publication in SCI/ SCOPUS indexed Journals.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Govt. funded Sponsored projects worth RS 16.3 Crores.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> 250+ Patents by faculty & students.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Customized Teaching-Learning methodology to make it more Student-Centric and Outcome-Based.</li>
                                </ul>
                            </div>
                            {/* <div class="about__btn wow animate__fadeInUp" data-wow-duration="0.7s">
                                <Link to="/about"> Read More </Link>
                            </div> */}
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp " data-wow-duration="0.3s">
                            {/* <img class="react__shape__1" src={shapeImg1} alt="Shape Image" /> */}
                            <img src={faculty} alt="About" width="100%" style={{marginTop:"100px"}}/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img class="react__shape__1" src={shapeImg1} alt="Shape Image" /> */}
                            <img src={academics} alt="About" width="100%" style={{marginTop:"100px"}}/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about__content">
                            <div>
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Academics & Skilling</h2>
                                <ul>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} />  Fully Flexible Choice-Based Credit System (CBCS) with an opportunity for each student to design their own program to get major, minor and honors degrees of their choice.</li>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} />  Competency building on language and communication skills through Cambridge University Empower.</li>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} />  Exclusive department to train students in advanced Technologies & Soft Skills making students Industry-ready.</li>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} />  33 Functional MoUs with MNCs & industries</li>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} />  Centre of excellences with Industry collaborations</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="about__content" style={{ marginTop: "-60px" }}>
                            <div>
                                <ul>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Competitive coding sessions to imbibe logical thinking and complex problem-solving skills; Hackathons and Mockathons for enhancing design and prototyping skills.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Proctored self-learning for advanced learners and hand-holding care to enhance learning abilities of slow learners.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Credits for skilling in all relevant courses and curriculum aligned with sector-skills council and MNCs.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Inclusion of value-added courses as part of academic mandate to make students industry ready.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Labs taken to class, project-based labs and tinkering labs to furnish students with hands-on expertise on contemporary skills.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Innovative learning pedagogies to make learning easy and enthusiastic.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Internationally reputed MOOCs embedded into academics.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Multi-disciplinary specializations to enhance product building abilities in students.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Comprehensive training and support for students to participate in national and international level competitions such as world skills competitions SAE-BAJA & Smart India Hackathon.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Training for competitive exams as per the choice of the students.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Academic credit sharing with study-abroad under Joint Certification programs in collaboration with internationally reputed Universities.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Global certifications from established skill academies and Centre of Excellences on campus.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Industry driven and vetted curriculum.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Exclusive student counseling for career guidance, academic growth, and personal well-being.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about__content">
                            <div>
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Infrastructure</h2>
                                <ul>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Wi-Fi enabled campus with 3 Gbps Band Width, ICT and Digital Technologies enabled classrooms.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Well-equipped State-of-the-Art Academic Laboratories</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Separate hostels for boys, girls and international students on-campus that serve North-Indian, South-Indian, and international cuisines.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Clean & Green campus.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> 24*7 In-House Clinic facility.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> ATM and Banking facility</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Food Courts & Stationery Stalls</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Arena for Praying (Temple, Mosque & Church within campus)</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img class="react__shape__1" src={shapeImg1} alt="Shape Image" /> */}
                            <img src={infrastructure} alt="About" width="100%" style={{marginTop:"100px"}}/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            {/* <img class="react__shape__1" src={shapeImg1} alt="Shape Image" /> */}
                            <img src={amenties} alt="About" width="100%" style={{marginTop:"100px"}}/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about__content">
                            <div>
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Amenities</h2>
                                <ul>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Student Clubs and professional society student chapters to enhance the student life skills and holistic development.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Annual national-level techno-management and cultural festivals & national level sports meets.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> NSS, NCC and Extension Activities for imbibing social responsibility among students.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Multi-Cuisine restaurants and 24X7 coffee shops on campus to facilitate the diverse student community.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> High end computers that facilitate students to use computational resources round the clock.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="about__content" style={{ marginTop: "-60px" }}>
                            <div>
                                <ul>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Indoor and Outdoor Sport facilities facilitated with State-of-the-Art indoor stadium.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Separate Gymnasiums for boys and girls.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> In-House Clinic as part of an MoU with Apollo Hospitals with 24X7 resident doctor and nursing staff.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Air-conditioned 500 capacity auditorium, 3000 capacity Amphitheatre with multiple seminar halls of varied capacities.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Exclusive centrally air-conditioned Library facility open till 10 PM on all days.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Transport facility from every nook & corner of Kakinada, Rajamahendravaram and their surroundings.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Ragging free environment with staggered timings for freshmen and the seniors.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Students are encouraged to be part of the faculty research leading to international journal paper publications.</li>
                                    <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Financial assistance for study through tie-up with banks and financial institutions.</li>
                               </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyADITYApart;