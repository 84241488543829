import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import AboutBreadcrumb from '../../components/Breadcrumb/about';
import ScrollToTop from '../../components/ScrollTop';


import Logo from '../../assets/images/logos/logo2.png';

const CoreValues = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={Logo}
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">

                    <AboutBreadcrumb pageTitle="Governing Body" />
                    <div class="col-md-12  col-sm-12 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                        <div class="container" style={{ width: '65%' }}>
                            {/* <div class="about__content"><br /> </div>
                            <div><h2 class="text-center hedingcolor">Members of  Governing Body </h2></div> */}

                            <div class="table-responsive  table-striped ">

                                <table class="table responsive">
                                    <thead>
                                        <tr>
                                            <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center" }}>S.No</th>
                                            <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center", width: '25%' }}>Name</th>

                                            <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center" }}>Designation</th>
                                            <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center", width: '26%' }}>Position in the committee</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>1</td>
                                            <td>Dr. N Sesha Reddy</td>
                                            <td className='center'>Chancellor</td>
                                            <td className='center'>Ex-officio  Chairperson </td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>2</td>
                                            <td>Dr. N Satish Reddy</td>
                                            <td className='center'>Pro-Chancellor</td>
                                            <td className='center'>Ex-officio Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>3</td>
                                            <td>Dr. M. Sreenivasa Reddy</td>
                                            <td className='center'>Deputy Pro-Chancellor</td>
                                            <td className='center'>Ex-officio Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>4</td>
                                            <td>Dr. M. B. Srinivas</td>
                                            <td className='center'>Vice-Chancellor</td>
                                            <td className='center'>Ex-officio Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>5</td>
                                            <td>Saurabh Gaur, IAS</td>
                                            <td className='center'>The Secretary, Higher Education, Govt of AP</td>
                                            <td className='center'>Ex-officio Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>6</td>
                                            <td>Prof. K. Rama Mohana Rao</td>
                                            <td className='center'>The Chairman, APSCHE</td>
                                            <td className='center'>Ex-officio Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>7</td>
                                            <td>Prof. Lalit Mohan Patnaik</td>
                                            <td className='center'>NASI Senior Scientist,Adjunct Professor, National Institute of Advanced Studies, Bangalore.</td>
                                            <td className='center'>Nominated Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>8</td>
                                            <td>Prof. Jigisha Parikh</td>
                                            <td className='center'>Professor, Chemical Engineering Department, SVNIT Gujarat, Scientist-G, SERB, DST.</td>
                                            <td className='center'>Nominated Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>9</td>
                                            <td>Mr. D. V. S. Narayana Raju</td>
                                            <td className='center'>Executive Director,  Deccan Fine Chemicals India Pvt. Ltd., Hyderabad </td>
                                            <td className='center'>Nominated Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>10</td>
                                            <td>CA. V.V. Satyanarayana</td>
                                            <td className='center'>Chartered Accountant</td>
                                            <td className='center'>Nominated Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>11</td>
                                            <td>Prof. Ram K. Sharma</td>
                                            <td className='center'>Vice-Chancellor, University of Petroleum and Energy Studies, Dehradun</td>
                                            <td className='center'>Nominated Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>12</td>
                                            <td>Prof. R. Venkata Rao</td>
                                            <td className='center'>Vice-Chancellor, India International University of Legal Education and Research, Goa.</td>
                                            <td className='center'>Nominated Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>13</td>
                                            <td>Dr. N. Suguna Reddy</td>
                                            <td className='center'>Joint Secretary, Sarojini Educational Society, Kakinada.</td>
                                            <td className='center'>Nominated Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>14</td>
                                            <td>Dr. N. Sruthi Reddy</td>
                                            <td className='center'>Member, Aditya Academy,Kakinada</td>
                                            <td className='center'>Nominated Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>15</td>
                                            <td>Dr. G. Suresh</td>
                                            <td className='center'>Registrar</td>
                                            <td className='center'>Ex-officio Member Secretary</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default CoreValues;