import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_aiml';
import Banner from './banner';



const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <Banner/>
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        
                                                        <p className='name'>Join the Computer Science Engineering - Artificial Intelligence and Machine Learning (AI and ML) program for an exciting journey into the future of technology. Our innovative curriculum, guided by experienced faculty and supported by advanced facilities, blends theoretical foundations with hands-on experience. From understanding deep learning techniques to developing intelligent systems, our program prepares students to excel in the rapidly advancing field of AI and ML.
                                                        </p>
                                                        <p className='name'>With a strong emphasis on the latest breakthroughs in AI and ML, including autonomous systems, natural language processing, and cognitive computing, students gain cutting-edge skills. Collaborations with industry leaders and research institutions ensure that students are exposed to the newest trends and technological advancements. Unlock your potential to drive the future of intelligent technology with us.
                                                        </p>
                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  VISION : </b> <br />

                                                        <p className='name'>
                                                            To become a recognized centre of excellence in the field of AIML that fulfil the needs of industry and society.
                                                        </p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> MISSION:</b><br />
                                                        <p className='name'>
                                                            M1: Impart the knowledge through states-of-the-art concepts, tools and techniques in Artificial Intelligence.<br />
                                                            M2: Promote technical competence by collaborating with Industries to provide solutions for future challenges.<br />
                                                            M3: Inculcate ethics, environmental and societal consciousness for social responsibility.
                                                            M4: Build leadership and life-long learning skills to ensure the holistic development of stakeholder.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;