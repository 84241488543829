import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const [submenuVisible, setSubmenuVisible] = useState(false);
    const [activeSubmenu, setActiveSubmenu] = useState('');
    const location = useLocation();

    const toggleSubmenu = () => {
        setSubmenuVisible(!submenuVisible);
    };

    const handleSubmenuClick = (path) => {
        setActiveSubmenu(path);
    };

    return (
        <div className="col-md-3 col-sm-3 col-0">
            <div className="side-navbar">
                <nav className="navbar">
                    <ul className="navbar-nav">
                        <li className="navbar-title text-center">
                            <a className="navbar-brand text-light1" href="#" style={{ padding: '12px 121px 12px 131px' }}>Explore</a>
                        </li>
                        <li className={`nav-item ${location.pathname === '/HBS' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/HBS">Overview</Link>
                        </li>
                        
                        <li className={`nav-item ${location.pathname === '/HBS_faculty' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/HBS_faculty">Faculty</Link>
                        </li>
                        
                        <br />
                    </ul>
                </nav>
                <div className="campus_sec1 row">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <Link to="/school_of_engineering" className="more-about" style={{ width: "100%" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left">
                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                    <polyline points="12 19 5 12 12 5"></polyline>
                                </svg> Home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
