import React from 'react';
import { useState } from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

import CePopUp from "../../../../../components/Popup/CE/CEPopUp";

// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_ce';
import Banner from './banner';





const CEview = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    <CePopUp /> 
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  B.Tech </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>B. Tech - Civil Engineering, the Undergraduate program of Civil Engineering provides the information and abilities needed for future generations of civil engineers to address urgent environmental issues. This involved incorporating classes on sustainable practices, like using energy-efficient designs and green building materials to reduce a building's environmental effects. Additionally, to make sure that building projects had as little of an environmental impact as possible, sustainable waste management was being stressed. The Civil Engineering curriculum was perhaps most significant in realizing the urgent necessity for the creation of climate-resilient infrastructure. Through the integration of climate change resilience measures, civil engineers have the potential to contribute to the construction of a more sustainable future for everybody</p>

                                                        <p className='name'>Through major core courses like solid mechanics, fluid mechanics, soil mechanics, environmental engineering, water resources management, structural analysis & design, transportation engineering, mathematics, and the core courses of the civil engineering program guarantee a solid scientific and analytical foundation for professional study. Students can achieve advanced levels of technical expertise in one or more areas of concentration by selecting appropriate minor degree courses, honour degree courses, ability, skill enhancement and value-added courses. As an alternative, students might decide to broaden their interests by mixing courses from different departments.</p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Civil Engineering <br/>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Civil Engineering  with Minor Specialization in
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Structural Engineering<br /> 
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Environmental Geotechnics<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Transportation Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Integrated Design for Industrial Facilities<b style={{ color: "#328afc",fontSize:"17px" }}>(Industry Integrated Program - L&T)</b><br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor Stream in Advanced Practices in Construction<b style={{ color: "#328afc",fontSize:"17px" }}>(Industry Integrated Program - L&T)</b><br />
                                                        </p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Civil Engineering  with 
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electrical and Electronics Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mechanical Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electronics and Communication Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Computer Science and Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Data Science<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Artificial Intelligence and Machine Learning<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Petroleum Technology<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mining Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Agricultural Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Entrepreneurship Development and Incubation<br />
                                                        </p>
                                                            
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;