
import { Link } from 'react-router-dom';
import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import AboutBreadcrumb from '../../components/Breadcrumb/AboutBreadcrumbs';
import ScrollToTop from '../../components/ScrollTop';

// Image
import aboutImg from '../../assets/images/about/deepak_reddy.jpg';
import shapeImg1 from '../../assets/images/about/shape_02.png';

const Chancellor = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={Logo}
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <AboutBreadcrumb pageTitle="Vice Chancellor's Message" />
                    <div class="about__area about2__area about3__area p-relative pb---60" style={{ marginTop: "40px" }}>
                        <div class="container">
                            <div class="row">
                                <div className="col-lg-6">
                                    <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                                        <img src={aboutImg} alt="About" />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="about__content">
                                        <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s" style={{ color: "#D2093C" }}>Vice Chancellor's Message </h2>
                                        <b style={{ fontSize: '18px' }}>Education is the key for unlocking a brighter tomorrow .
                                        </b>
                                        <br/>
                                        <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s" style={{ textAlign: 'justify' }}>
                                            As Pro Chancellor, I'm thrilled to invite you to join our community dedicated to excellence and innovation in technical education. At Aditya, we prioritize interdisciplinary skills and leadership development, preparing our students to tackle global challenges. Our inclusive environment fosters holistic growth through diverse co-curricular activities, while our commitment to public service drives impactful research and societal change.<br/>

                                            Join us at Aditya University, where your potential meets limitless opportunities for success.</p>
                                        
                                        <b style={{ float: 'right' }}>
                                            Best regards,<br />
                                            Mr.N.Deepak Reddy.<br />
                                            Pro-Chancellor,<br />
                                            Aditya University

                                        </b>
                                    </div>
                                </div>
                               


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default Chancellor;