import React, { useState, useEffect } from "react";
import { Route, Routes } from 'react-router-dom';
import Preloader from "../components/Preloader";

//Custom Components

import Home from '../pages/home';
import HomeTwo from '../pages/home-2';
import HomeThree from '../pages/home-3';
import About from '../pages/about';
import Course from '../pages/course';
import CourseList from '../pages/course/course-list';
import CourseDetails from '../pages/course/course-details';
import CourseSidebar from '../pages/course/course-sidebar';
import Instructor from '../pages/instructor';
import InstructorDetails from '../pages/instructor/instructor-details';
import Event from '../pages/event';
import EventSidebar from '../pages/event/event-sidebar';
import EventDetails from '../pages/event/event-details';
import Blog from '../pages/blog';
import BlogDetails from '../pages/blog/blog-details';
import Login from '../pages/authentication/login';
// import Registration from '../pages/authentication/registration';
import Signup from '../pages/authentication/signup';
import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/ScrollTop/LoadTop'
import Admission from '../pages/admissions';
// import Scholarship from '../pages/scholarship/index';
import Payment from '../pages/payment/payment';
import Policy from '../pages/policy/policy';

// About menu
import Overview from '../pages/about/overview';
import VisionMission from '../pages/about/VisionMission';
import Chancellor from '../pages/about/Chancellor';
import ProChancellor from '../pages/about/pro_chancellor';
import ViceChancellor from '../pages/about/vioc_chancellor';
import Board_of_management from '../pages/about/board_of_management';
import Governing_Body_Members from '../pages/about/governing_body_members';
import Academiccouncils_Members from '../pages/about/academiccouncils';
import Finance_committee from '../pages/about/finance_committee';

import CoreValues from '../pages/about/CoreValues';

// credentials
import Accreditations from '../pages/about/credentials/accreditations';
import Rankings from '../pages/about/credentials/rankings';
import Recognitions from '../pages/about/credentials/recognitions';
import NIRF from '../pages/about/credentials/nirf';
import Accreditations_rankings from '../pages/about/credentials/accreditations_rankings';
//iqac
import IQAC from '../pages/about/iqac/iqac';
import IQAC_objectives from '../pages/about/iqac/objectives';
import IQAC_strategies from '../pages/about/iqac/strategies';
import IQAC_functions from '../pages/about/iqac/functions';
import IQAC_benefits from '../pages/about/iqac/benefits';
import IQAC_Members from '../pages/about/iqac/iqac_members';
import IQAC_Events from '../pages/about/iqac/iqac_events';












// study menu
import WhyADITYA from '../pages/study/WhyADITYA';
import EligibilityCriteria from '../pages/study/EligibilityCriteria';

// import ProgramsOffered from '../pages/study/ProgramsOffered';
// import DiplomaProgramsOffered from '../pages/study/ProgramsOffered_new';
import Programs from '../pages/study/programsoffered/ProgramsOffered_new';
import UGProgramsOffered from '../pages/study/programsoffered/UGPrograms';
import PGProgramsOffered from '../pages/study/programsoffered/PGPrograms';
import PHDProgramsOffered from '../pages/study/programsoffered/phdprograms';


import CorporateProgramsOffered from '../pages/study/programsoffered/corporateprograms';
import Diplomaprograms from '../pages/study/programsoffered/diplomaprograms';

import AdmissionProcess from '../pages/study/AdmissionProcess';
import HostelFacilities from '../pages/study/HostelFacilities';
import FeesScholarship from '../pages/study/FeesScholarship';
import Fees from '../pages/study/Feesmain';
import Scholarship from '../pages/study/scholarshipsmain';


// Students menu lo Academics 
import AcademicsOverview from '../pages/students/academics/academics_overview';
import Holidays from '../pages/students/academics/holidays';

import EngineeringOverview from '../pages/students/academics/Engineering/eng_overview';
import Curriculum_Engineering from '../pages/students/academics/Engineering/ug_program_curriculum';
import Curriculum_Engineering_new from '../pages/students/academics/Engineering/ug_program_curriculum_new';

import MCAcurriculum from '../pages/students/academics/Engineering/pg_curriculum';
import UG_regulations from '../pages/students/academics/Engineering/ug_regulations';
import PGregulations from '../pages/students/academics/Engineering/pg-regulations';

import PHD_syllabus from '../pages/students/academics/Engineering/phd_syllabus';

import B_Tech_2024_25 from '../pages/students/academics/Engineering/academic_calendar';


import ManagementOverview from '../pages/students/academics/Management/management_overview';
import MBAcurriculum from '../pages/students/academics/Management/curriculum';
import PG_regulations from '../pages/students/academics/Management/regulations';

import MB_Aacademic_calendar from '../pages/students/academics/Management/mba_academic_calendar';






// Department

// Civil
import CivilDept from '../pages/students/academics/Department/CE/ce_overview';
import CEPO from '../pages/students/academics/Department/CE/ce_programs_offered';
import CEEvents from '../pages/students/academics/Department/CE/ce_events';
import CEFaculty from '../pages/students/academics/Department/CE/ce_faculty';
import CEPlacements from '../pages/students/academics/Department/CE/ce_placements';
import CEUG from '../pages/students/academics/Department/CE/ce_ug';
import CEPG from '../pages/students/academics/Department/CE/ce_pg';
import CEPHD from '../pages/students/academics/Department/CE/ce_phd';



// EEE
import EEEDept from '../pages/students/academics/Department/EEE/eee_overview';
import EEEPO from '../pages/students/academics/Department/EEE/eee_programs_offered';
import EEEEvents from '../pages/students/academics/Department/EEE/eee_events';
import EEEFaculty from '../pages/students/academics/Department/EEE/eee_faculty';
import EEEPlacements from '../pages/students/academics/Department/EEE/eee_placements';
import EEEUG from '../pages/students/academics/Department/EEE/eee_ug';
import EEEPG from '../pages/students/academics/Department/EEE/eee_pg';
import EEEPHD from '../pages/students/academics/Department/EEE/eee_phd';


// Mech
import MechDept from '../pages/students/academics/Department/MECH/mech_overview';
import MechPO from '../pages/students/academics/Department/MECH/mech_programs_offered';
import MechEvents from '../pages/students/academics/Department/MECH/mech_events';
import MechFaculty from '../pages/students/academics/Department/MECH/mech_faculty';
import MechPlacements from '../pages/students/academics/Department/MECH/mech_placements';
import MechUG from '../pages/students/academics/Department/MECH/mech_ug';
import MechPG from '../pages/students/academics/Department/MECH/mech_pg';
import MechPHD from '../pages/students/academics/Department/MECH/mech_phd';


// ECE
import ECEDept from '../pages/students/academics/Department/ECE/ece_overview';
import ECEPO from '../pages/students/academics/Department/ECE/ece_programs_offered';
import ECEEvents from '../pages/students/academics/Department/ECE/ece_events';
import ECEFaculty from '../pages/students/academics/Department/ECE/ece_faculty';
import ECEPlacements from '../pages/students/academics/Department/ECE/ece_placements';
import ECEUG from '../pages/students/academics/Department/ECE/ece_ug';
import ECEPG from '../pages/students/academics/Department/ECE/ece_pg';
import ECEPHD from '../pages/students/academics/Department/ECE/ece_phd';

// CSE
import CSEDept from '../pages/students/academics/Department/CSE/cse_overview';
import CSEPO from '../pages/students/academics/Department/CSE/cse_programs_offered';
import CSEEvents from '../pages/students/academics/Department/CSE/cse_events';
import CSEFaculty from '../pages/students/academics/Department/CSE/cse_faculty';
import CSEPlacements from '../pages/students/academics/Department/CSE/cse_placements';
import CSEUG from '../pages/students/academics/Department/CSE/cse_ug';
import CSEPG from '../pages/students/academics/Department/CSE/cse_pg';
import CSEPHD from '../pages/students/academics/Department/CSE/cse_phd';

// HBS
import HBSDept from '../pages/students/academics/Department/HBS/hbs_overview';
import HBSFaculty from '../pages/students/academics/Department/HBS/hbs_faculty';

// mca
import MCA from '../pages/students/academics/Department/MCA/mca_overview';
import MCAFaculty from '../pages/students/academics/Department/MCA/mca_faculty';


// IT
import ITDept from '../pages/students/academics/Department/IT/it_overview';
import ITPO from '../pages/students/academics/Department/IT/it_programs_offered';
import ITEvents from '../pages/students/academics/Department/IT/it_events';
import ITFaculty from '../pages/students/academics/Department/IT/it_faculty';
import ITPlacements from '../pages/students/academics/Department/IT/it_placements';
import ITUG from '../pages/students/academics/Department/IT/it_ug';
import ITPG from '../pages/students/academics/Department/IT/it_pg';
import ITPHD from '../pages/students/academics/Department/IT/it_phd';

// AIML
import AIMLDept from '../pages/students/academics/Department/AIML/aiml_overview';
import AIMLPO from '../pages/students/academics/Department/AIML/aiml_programs_offered';
import AIMLEvents from '../pages/students/academics/Department/AIML/aiml_events';
import AIMLFaculty from '../pages/students/academics/Department/AIML/aiml_faculty';
import AIMLPlacements from '../pages/students/academics/Department/AIML/aiml_placements';
import AIMLUG from '../pages/students/academics/Department/AIML/aiml_ug';
import AIMLPG from '../pages/students/academics/Department/AIML/aiml_pg';
import AIMLPHD from '../pages/students/academics/Department/AIML/aiml_phd';

// DS
import DSDept from '../pages/students/academics/Department/DS/ds_overview';
import DSPO from '../pages/students/academics/Department/DS/ds_programs_offered';
import DSEvents from '../pages/students/academics/Department/DS/ds_events';
import DSFaculty from '../pages/students/academics/Department/DS/ds_faculty';
import DSPlacements from '../pages/students/academics/Department/DS/ds_placements';
import DSUG from '../pages/students/academics/Department/DS/ds_ug';
import DSPG from '../pages/students/academics/Department/DS/ds_pg';
import DSPHD from '../pages/students/academics/Department/DS/ds_phd';

// Petroleum
import PetroleumDept from '../pages/students/academics/Department/PT/pt_overview';
import PetroleumPO from '../pages/students/academics/Department/PT/pt_programs_offered';
import PetroleumEvents from '../pages/students/academics/Department/PT/pt_events';
import PetroleumFaculty from '../pages/students/academics/Department/PT/pt_faculty';
import PetroleumPlacements from '../pages/students/academics/Department/PT/pt_placements';
import PetroleumUG from '../pages/students/academics/Department/PT/pt_ug';
import PetroleumPG from '../pages/students/academics/Department/PT/pt_pg';
import PetroleumPHD from '../pages/students/academics/Department/PT/pt_phd';

// Mining
import MiningDept from '../pages/students/academics/Department/Mining/mining_overview';
import MiningPO from '../pages/students/academics/Department/Mining/mining_programs_offered';
import MiningEvents from '../pages/students/academics/Department/Mining/mining_events';
import MiningFaculty from '../pages/students/academics/Department/Mining/mining_faculty';
import MiningPlacements from '../pages/students/academics/Department/Mining/mining_placements';
import MiningUG from '../pages/students/academics/Department/Mining/mining_ug';
import MiningPG from '../pages/students/academics/Department/Mining/mining_pg';
import MiningPHD from '../pages/students/academics/Department/Mining/mining_phd';

// Agricultural
import AgriculturalDept from '../pages/students/academics/Department/AGRI/agri_overview';
import AgriculturalPO from '../pages/students/academics/Department/AGRI/agri_programs_offered';
import AgriculturalEvents from '../pages/students/academics/Department/AGRI/agri_events';
import AgriculturalFaculty from '../pages/students/academics/Department/AGRI/agri_faculty';
import AgriculturalPlacements from '../pages/students/academics/Department/AGRI/agri_placements';
import AgriculturalUG from '../pages/students/academics/Department/AGRI/agri_ug';
import AgriculturalPG from '../pages/students/academics/Department/AGRI/agri_pg';
import AgriculturalPHD from '../pages/students/academics/Department/AGRI/agri_phd';

// MBA
import MBADept from '../pages/students/academics/Department/MBA/mba_overview';
import MBAPO from '../pages/students/academics/Department/MBA/mba_programs_offered';
import MBAEvents from '../pages/students/academics/Department/MBA/mba_events';
import MBAFaculty from '../pages/students/academics/Department/MBA/mba_faculty';
import MBAPlacements from '../pages/students/academics/Department/MBA/mba_placements';

// Opportunities
import Opportunities from '../pages/students/opportunities/overview';
import Opportunities_Trainings from '../pages/students/opportunities/trainings';
import Opportunities_higher_education from '../pages/students/opportunities/higher_education';
import Opportunities_Internships from '../pages/students/opportunities/internships';
import Opportunities_entrepreneurship from '../pages/students/opportunities/entrepreneurship';
import Opportunities_contact_us from '../pages/students/opportunities/contact_us';
import Opportunities_placement_policy from '../pages/students/opportunities/placement_policy';
import Opportunities_studenttestimonials from '../pages/students/opportunities/studenttestimonials';
import Opportunities_top_recruiters from '../pages/students/opportunities/top_recruiters';









// Research menu
import OurResearch from '../pages/research/OurResearch';
import Patents from '../pages/research/patents_commercialisation/overview';
import Automatic_waste from '../pages/research/patents_commercialisation/waste_cleaning_system';
import Automatic_sorting from '../pages/research/patents_commercialisation/automatic_sorting';
import Cooking_system from '../pages/research/patents_commercialisation/cooking_system';
import Sugarcane_harvesting from '../pages/research/patents_commercialisation/sugarcane_harvesting';
import Bottle_configuration from '../pages/research/patents_commercialisation/bottle_configuration';
import Chambered_bottle from '../pages/research/patents_commercialisation/chambered_bottle';
import Virtual_reality from '../pages/research/patents_commercialisation/virtual_reality';
import Fractal_based from '../pages/research/patents_commercialisation/fractal_based';
import Water_repellent from '../pages/research/patents_commercialisation/water_repellent';
import Motorcycle from '../pages/research/patents_commercialisation/motorcycle';
import Clutch_system from '../pages/research/patents_commercialisation/clutch_system';
import Wearable_Device from '../pages/research/patents_commercialisation/wearable_Device';
import Toilet_commode from '../pages/research/patents_commercialisation/toilet_commode';
import Multi_Chamber from '../pages/research/patents_commercialisation/multi_Chamber';
import Mineralizing_water from '../pages/research/patents_commercialisation/mineralizing_water';
import Sanitizer from '../pages/research/patents_commercialisation/sanitizer';
import Retrieval_system from '../pages/research/patents_commercialisation/retrieval_system';



//careers
import Careers from '../pages/careers/careers_new';
import Careers_new from '../pages/careers/careers';

import Icmes2025 from '../pages/careers/icmes';
import Harshavardhini from '../pages/careers/harshavardhini';
import Rdecs2025 from '../pages/careers/rdecs2025';









//International Website 
import International from '../pages/international/overview';
import InternationalCourses from '../pages/international/international_courses';
import International_Admission_Process from '../pages/international/admissions/admission_process';
import International_Fee_Particulars from '../pages/international/admissions/fee_particulars';
import International_Scholarships from '../pages/international/admissions/scholarships';
import International_Admission_rules from '../pages/international/admissions/admission_rules';
import International_Payment_procedure from '../pages/international/admissions/payment_procedure';
import International_Information_booklet from '../pages/international/admissions/information_booklet';

import International_visa_assistance from '../pages/international/information_students/visa_assistance';
import International_pre_arrival_information from '../pages/international/information_students/pre_arrival_information';
import International_post_arrival_information from '../pages/international/information_students/post_arrival_information';
import International_guidelines from '../pages/international/information_students/guidelines';
import International_contact from '../pages/international/contact';
import International_apply_now from '../pages/international/apply_now';




import PHD_admission_form from '../pages/reg/admission';
import PHD_admission_form_new from '../pages/reg/comming';
// import Veda_2024 from '../pages/reg/comming_soon';


import Review from '../pages/reg/review_admission';
import PHDpayment from '../pages/reg/payment';
import PHDPDF from '../pages/reg/pdfgenerate';



// import Testing_api from '../pages/students/academics/testing_api';
// outreach
import Learning_academy from '../pages/outreach/learning_academy/about_aditya_learning_academy';
import Learning_academy_objectives from '../pages/outreach/learning_academy/objectives';
import Learning_academy_support_services from '../pages/outreach/learning_academy/comprehensive_support_services';
import Learning_academy_team from '../pages/outreach/learning_academy/team';
import Learning_academy_events from '../pages/outreach/learning_academy/events';


import Institute from '../pages/outreach/collaborations/institute';
import Industry from '../pages/outreach/collaborations/industry';

import Alumni from '../pages/outreach/alumni/alumni';
import Community_Activities from '../pages/outreach/community_activities/community_activities';



// import InternationalPG from '../pages/international/PGPrograms';



const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        // Simulate data loading delay
        setTimeout(() => {
        setIsLoading(false);
        }, 500);
    }, []);

    return (
        <div className='App'>
            {isLoading ?
                <Preloader /> : ''
            }
            <>
                <LoadTop />
                <Routes>
                    <Route path="/" exact element={<Home />} />
                    <Route path="/home-2" element={<HomeTwo />} />
                    <Route path="/home-3" element={<HomeThree />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/course" exact element={<Course />} />
                    <Route path="/course-list" exact element={<CourseList />} />
                    <Route path="/course/:id" element={<CourseDetails />} />
                    <Route path="/course-sidebar" element={<CourseSidebar />} />
                    <Route path="/event" exact element={<Event />} />
                    <Route path="/event/:id" element={<EventDetails />} />
                    <Route path="/event-sidebar" element={<EventSidebar />} />
                    <Route path="/blog" exact element={<Blog />} />
                    <Route path="/blog/:id" element={<BlogDetails />} />
                    <Route path="/instructor" exact element={<Instructor />} />
                    <Route path="/instructor/:id" exact element={<InstructorDetails />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path='*' element={<Error />} />
                    <Route path="/admissions" exact element={<Admission />} />
                    <Route path="/overview"  element={<Overview />} />
                    <Route path="/corevalues"  element={<CoreValues />} />
                    <Route path="/visionmission"  element={<VisionMission />} />
                    <Route path="/Chancellor"  element={<Chancellor />} />
                    <Route path="/Pro_Chancellor"  element={<ProChancellor />} />
                    <Route path="/Vice_Chancellor"  element={<ViceChancellor />} />
                    <Route path="/board_of_management"  element={<Board_of_management />} />
                    <Route path="/governing_body_members"  element={<Governing_Body_Members />} />
                    <Route path="/academic_councils_members"  element={<Academiccouncils_Members />} />
                    <Route path="/finance_committee"  element={<Finance_committee />} />



                    

                    

                    

                    <Route path="/accreditations"  element={<Accreditations />} />
                    <Route path="/rankings"  element={<Rankings />} />
                    <Route path="/recognitions"  element={<Recognitions />} />
                    <Route path="/nirf"  element={<NIRF />} />
                    <Route path="/NAAC_public_notice"  element={<Accreditations_rankings />} />

                    <Route path="/iqac"  element={<IQAC />} />
                    <Route path="/iqac_objectives"  element={<IQAC_objectives />} />
                    <Route path="/iqac_strategies"  element={<IQAC_strategies />} />
                    <Route path="/iqac_functions"  element={<IQAC_functions />} />
                    <Route path="/iqac_benefits"  element={<IQAC_benefits />} />
                    <Route path="/iqac_members"  element={<IQAC_Members />} />
                    <Route path="/iqac_events"  element={<IQAC_Events />} />


                    


                    


                    
                    {/* <Route path="/scholarship" exact element={<Scholarship />} /> */}

                     {/* study menu */}
                     <Route path="/whyaditya"  element={<WhyADITYA />} />
                     <Route path="/eligibilitycriteria"  element={<EligibilityCriteria />} />
                     <Route path="/programsoffered"  element={<Programs/>} />

                     {/* <Route path="/programsoffered_new"  element={<ProgramsOffered />} />
                     <Route path="/programsoffered_diploma"  element={<DiplomaProgramsOffered />} /> */}

                     <Route path="/diploma_programs"  element={<Diplomaprograms />} />
                     <Route path="/programsoffered_ug"  element={<UGProgramsOffered />} />
                     <Route path="/programsoffered_pg"  element={<PGProgramsOffered />} />
                     <Route path="/phd_programs"  element={<PHDProgramsOffered />} />
                     <Route path="/phd_syllabus"  element={<PHD_syllabus />} />

                     
                     <Route path="/corporate_programs"  element={<CorporateProgramsOffered />} />


                     <Route path="/admissionprocess"  element={<AdmissionProcess />} />
                     <Route path="/hostelfacilities"  element={<HostelFacilities />} />
                     <Route path="/fees&scholarship"  element={<FeesScholarship />} />
                     <Route path="/fees"  element={<Fees />} />
                     <Route path="/scholarship"  element={<Scholarship />} />


                     {/* Research menu */}
                     <Route path="/OurResearch"  element={<OurResearch />} />
                     <Route path="/Patents_Commercialisation"  element={<Patents />} />
                     <Route path="/Automatic_waste"  element={<Automatic_waste />} />
                     <Route path="/Automatic_sorting"  element={<Automatic_sorting />} />
                     <Route path="/Cooking_system"  element={<Cooking_system />} />
                     <Route path="/Sugarcane_harvesting"  element={<Sugarcane_harvesting />} />
                     <Route path="/Bottle_configuration"  element={<Bottle_configuration />} />
                     <Route path="/Chambered_bottle"  element={<Chambered_bottle />} />
                     <Route path="/virtual_reality"  element={<Virtual_reality />} />
                     <Route path="/fractal_based"  element={< Fractal_based />} />
                     <Route path="/water_repellent"  element={<  Water_repellent />} />
                     <Route path="/motorcycle"  element={<Motorcycle />} />
                     <Route path="/clutch_system"  element={< Clutch_system />} />
                     <Route path="/wearable_Device"  element={< Wearable_Device />} />
                     <Route path="/Toilet_commode"  element={< Toilet_commode />} />
                     <Route path="/mineralizing_water"  element={< Mineralizing_water />} />
                     <Route path="/Multi_Chamber"  element={< Multi_Chamber />} />
                     <Route path="/sanitizer"  element={< Sanitizer />} />
                     <Route path="/retrieval_system"  element={< Retrieval_system />} />



                     {/* careers */}
                     <Route path="/careers"  element={<Careers />} />
                     <Route path="/Careers_new"  element={<Careers_new />} />
                     <Route path="/icmes2025"  element={<Icmes2025 />} />
                     <Route path="/harshavardhini"  element={<Harshavardhini />} />
                     <Route path="/rdecs2025"  element={<Rdecs2025 />} />


                     



                     


                     {/* careers */}
                     <Route path="/payment"  element={<Payment />} />
                     
                     {/* careers */}
                     <Route path="/policy"  element={<Policy />} />

                     {/* students menu */}
                     {/* Academics */}
                        <Route path="/academics"  element={<AcademicsOverview />} />
                        <Route path="/holidays"  element={<Holidays />} />

                        <Route path="/school_of_engineering"  element={<EngineeringOverview />} />
                        <Route path="/ug_program_curriculum"  element={<Curriculum_Engineering />} />
                        <Route path="/ug_program_curriculum_new"  element={<Curriculum_Engineering_new />} />

                        <Route path="/school_of_business"  element={<ManagementOverview />} />
                        <Route path="/mba_curriculum"  element={<MBAcurriculum />} />
                        <Route path="/pg_program_curriculum"  element={<MCAcurriculum />} />
                        <Route path="/ug_regulations"  element={<UG_regulations />} />
                        <Route path="/pg-regulations"  element={<PGregulations />} />


                        

                        
                        <Route path="/academic_calendar"  element={<B_Tech_2024_25 />} />
                        <Route path="/MBA_academic_calendar"  element={<MB_Aacademic_calendar />} />
                        <Route path="/pg_regulations"  element={<PG_regulations />} />

                        


                        




                        

                    {/* Department */}
                    {/* Civil */}
                    <Route path="/Civil"  element={<CivilDept />} />
                    <Route path="/Civil_programs_offered"  element={<CEPO />} />
                    <Route path="/Civil_events"  element={<CEEvents />} />
                    <Route path="/Civil_faculty"  element={<CEFaculty />} />
                    <Route path="/Civil_placements"  element={<CEPlacements />} />
                    <Route path="/Civil_PG_program"  element={<CEPG />} />
                    <Route path="/Civil_UG_program"  element={<CEUG/>} />
                    <Route path="/Civil_PHD_program"  element={<CEPHD />} />


                    {/* EEE */}
                    <Route path="/EEE"  element={<EEEDept />} />
                    <Route path="/EEE_programs_offered"  element={<EEEPO />} />
                    <Route path="/EEE_events"  element={<EEEEvents />} />
                    <Route path="/EEE_faculty"  element={<EEEFaculty />} />
                    <Route path="/EEE_placements"  element={<EEEPlacements />} />
                    <Route path="/EEE_PG_program"  element={<EEEPG />} />
                    <Route path="/EEE_UG_program"  element={<EEEUG/>} />
                    <Route path="/EEE_PHD_program"  element={<EEEPHD />} />

                    {/* Mechanical */}
                    <Route path="/Mechanical"  element={<MechDept />} />
                    <Route path="/Mechanical_programs_offered"  element={<MechPO />} />
                    <Route path="/Mechanical_events"  element={<MechEvents />} />
                    <Route path="/Mechanical_faculty"  element={<MechFaculty />} />
                    <Route path="/Mechanical_placements"  element={<MechPlacements />} />
                    <Route path="/Mechanical_PG_program"  element={<MechPG />} />
                    <Route path="/Mechanical_UG_program"  element={<MechUG/>} />
                    <Route path="/Mechanical_PHD_program"  element={<MechPHD />} />


                    {/* ECE */}
                    <Route path="/ECE"  element={<ECEDept />} />
                    <Route path="/ECE_programs_offered"  element={<ECEPO />} />
                    <Route path="/ECE_events"  element={<ECEEvents />} />
                    <Route path="/ECE_faculty"  element={<ECEFaculty />} />
                    <Route path="/ECE_placements"  element={<ECEPlacements />} />
                    <Route path="/ECE_PG_program"  element={<ECEPG />} />
                    <Route path="/ECE_UG_program"  element={<ECEUG/>} />
                    <Route path="/ECE_PHD_program"  element={<ECEPHD />} />

                    {/* CSE */}
                    <Route path="/CSE"  element={<CSEDept />} />
                    <Route path="/CSE_programs_offered"  element={<CSEPO />} />
                    <Route path="/CSE_events"  element={<CSEEvents />} />
                    <Route path="/CSE_faculty"  element={<CSEFaculty />} />
                    <Route path="/CSE_placements"  element={<CSEPlacements />} />
                    <Route path="/CSE_PG_program"  element={<CSEPG />} />
                    <Route path="/CSE_UG_program"  element={<CSEUG/>} />
                    <Route path="/CSE_PHD_program"  element={<CSEPHD />} />

                    {/* HBS */}
                    <Route path="/HBS"  element={<HBSDept />} />
                    <Route path="/HBS_faculty"  element={<HBSFaculty />} />

                    {/* MCA */}
                    <Route path="/computer_applications"  element={<MCA />} />
                    <Route path="/MCA_faculty"  element={<MCAFaculty />} />

                    

                    
                    {/* IT */}
                    <Route path="/IT"  element={<ITDept />} />
                    <Route path="/IT_programs_offered"  element={<ITPO />} />
                    <Route path="/IT_events"  element={<ITEvents />} />
                    <Route path="/IT_faculty"  element={<ITFaculty />} />
                    <Route path="/IT_placements"  element={<ITPlacements />} />
                    <Route path="/IT_PG_program"  element={<ITPG />} />
                    <Route path="/IT_UG_program"  element={<ITUG/>} />
                    <Route path="/IT_PHD_program"  element={<ITPHD />} />

                    {/* AIML */}
                    <Route path="/AIML"  element={<AIMLDept />} />
                    <Route path="/AIML_programs_offered"  element={<AIMLPO />} />
                    <Route path="/AIML_events"  element={<AIMLEvents />} />
                    <Route path="/AIML_faculty"  element={<AIMLFaculty />} />
                    <Route path="/AIML_placements"  element={<AIMLPlacements />} />
                    <Route path="/AIML_PG_program"  element={<AIMLPG />} />
                    <Route path="/AIML_UG_program"  element={<AIMLUG/>} />
                    <Route path="/AIML_PHD_program"  element={<AIMLPHD />} />

                    {/* DS */}
                    <Route path="/DS"  element={<DSDept />} />
                    <Route path="/DS_programs_offered"  element={<DSPO />} />
                    <Route path="/DS_events"  element={<DSEvents />} />
                    <Route path="/DS_faculty"  element={<DSFaculty />} />
                    <Route path="/DS_placements"  element={<DSPlacements />} />
                    <Route path="/DS_PG_program"  element={<DSPG />} />
                    <Route path="/DS_UG_program"  element={<DSUG/>} />
                    <Route path="/DS_PHD_program"  element={<DSPHD />} />

                    {/* Petroleum */}
                    <Route path="/Petroleum"  element={<PetroleumDept />} />
                    <Route path="/Petroleum_programs_offered"  element={<PetroleumPO />} />
                    <Route path="/Petroleum_events"  element={<PetroleumEvents />} />
                    <Route path="/Petroleum_faculty"  element={<PetroleumFaculty />} />
                    <Route path="/Petroleum_placements"  element={<PetroleumPlacements />} />
                    <Route path="/Petroleum_PG_program"  element={<PetroleumPG />} />
                    <Route path="/Petroleum_UG_program"  element={<PetroleumUG/>} />
                    <Route path="/Petroleum_PHD_program"  element={<PetroleumPHD />} />

                    {/* Mining */}
                    <Route path="/Mining"  element={<MiningDept />} />
                    <Route path="/Mining_programs_offered"  element={<MiningPO />} />
                    <Route path="/Mining_events"  element={<MiningEvents />} />
                    <Route path="/Mining_faculty"  element={<MiningFaculty />} />
                    <Route path="/Mining_placements"  element={<MiningPlacements />} />
                    <Route path="/Mining_PG_program"  element={<MiningPG />} />
                    <Route path="/Mining_UG_program"  element={<MiningUG/>} />
                    <Route path="/Mining_PHD_program"  element={<MiningPHD />} />


                    {/* Agricultural */}
                    <Route path="/Agricultural"  element={<AgriculturalDept />} />
                    <Route path="/Agricultural_programs_offered"  element={<AgriculturalPO />} />
                    <Route path="/Agricultural_events"  element={<AgriculturalEvents />} />
                    <Route path="/Agricultural_faculty"  element={<AgriculturalFaculty />} />
                    <Route path="/Agricultural_placements"  element={<AgriculturalPlacements />} />
                    <Route path="/Agricultural_PG_program"  element={<AgriculturalPG />} />
                    <Route path="/Agricultural_UG_program"  element={<AgriculturalUG/>} />
                    <Route path="/Agricultural_PHD_program"  element={<AgriculturalPHD />} />


                     {/* MBA */}
                    <Route path="/MBA"  element={<MBADept />} />
                    <Route path="/MBA_programs_offered"  element={<MBAPO />} />
                    <Route path="/MBA_events"  element={<MBAEvents />} />
                    <Route path="/MBA_faculty"  element={<MBAFaculty />} />
                    <Route path="/MBA_placements"  element={<MBAPlacements />} />

                    {/* Opportunities */}
                    <Route path="/Opportunities"  element={<Opportunities />} />
                    <Route path="/Trainings"  element={<Opportunities_Trainings />} />
                    <Route path="/Higher_education"  element={<Opportunities_higher_education />} />
                    <Route path="/Internships"  element={<Opportunities_Internships />} />
                    <Route path="/Entrepreneurship_incubation"  element={<Opportunities_entrepreneurship />} />
                    <Route path="/contact_us"  element={<Opportunities_contact_us />} />
                    <Route path="/placement_rules"  element={<Opportunities_placement_policy />} />
                    <Route path="/student_testimonials"  element={<Opportunities_studenttestimonials />} />
                    <Route path="/top_recruiters" element={<Opportunities_top_recruiters />} />




                    

                    




                    {/* International Website  */}
                    <Route path="/international"  element={<International />} />
                    <Route path="/international/Courses_Offered"  element={<InternationalCourses />} />
                    <Route path="/international/Admission_Process"  element={<International_Admission_Process />} />
                    <Route path="/international/Fee_Particulars"  element={<International_Fee_Particulars />} />
                    <Route path="/international/scholarships"  element={< International_Scholarships/>} />
                    <Route path="/international/admission_rules"  element={< International_Admission_rules/>} />
                    <Route path="/international/payment_procedure"  element={< International_Payment_procedure/>} />
                    <Route path="/international/information_booklet"  element={< International_Information_booklet/>} />

                    <Route path="/international/visa_assistance"  element={< International_visa_assistance/>} />
                    <Route path="/international/pre_arrival_information"  element={< International_pre_arrival_information/>} />
                    <Route path="/international/post_arrival_information"  element={< International_post_arrival_information/>} />
                    <Route path="/international/guidelines"  element={< International_guidelines/>} />
                    <Route path="/international/contact"  element={< International_contact/>} />
                    <Route path="/international/apply_now"  element={< International_apply_now/>} />


                    <Route path="/PHD"  element={< PHD_admission_form/>} />
                    <Route path="/PhD_admission_form"  element={< PHD_admission_form_new/>} />
                    {/* <Route path="/veda2024"  element={< Veda_2024/>} /> */}


                    <Route path="/Review_admission_form"  element={< Review/>} />
                    <Route path="/phdpayment"  element={< PHDpayment/>} />
                    <Route path="/pdfview"  element={< PHDPDF/>} />



                    <Route path="/aditya_learning_academy"  element={< Learning_academy/>} />
                    <Route path="/objectives"  element={< Learning_academy_objectives/>} />
                    <Route path="/comprehensive_support_services"  element={< Learning_academy_support_services/>} />
                    <Route path="/academy_team"  element={< Learning_academy_team/>} />
                    <Route path="/academy_events"  element={< Learning_academy_events/>} />

                    

                    <Route path="/collaborations"  element={< Institute/>} />
                    <Route path="/industry"  element={< Industry/>} />
                    <Route path="/alumni"  element={< Alumni/>} />
                    <Route path="/community_activities"  element={< Community_Activities/>} />



                    


                    {/* <Route path="/Testing_api"  element={< Testing_api/>} /> */}



                    {/* <Route path="/international/international_PG_programs"  element={<InternationalPG />} /> */}
                    


                    

             </Routes>
            </>
        </div>
    );
}

export default App;
