import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/aditya_learning_academy';
import ScrollToTop from '../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_learning_academy';



const overview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                <StudyBreadcrumb pageTitle="Aditya Learning Academy" pageName="About" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Aditya Learning Academy (Centre for Teaching & Learning), Aditya University </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>A Centre for Teaching & Learning at Aditya University under the name “ADITYA LEARNING ACADEMY (ALA)”, is established to enhance Teaching & Learning, support curriculum development, foster Collaborative partnerships, expand Digital educations, and perform several other functions that facilitate Aditya University to be a leader in educational innovation and faculty development</p>
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> <img src={point} style={{ height: "25px" }} />   Vision </b>
                                                        <p className='name'>To be a leader in educational innovation and faculty development, fostering an environment where teaching excellence and learner success converge through collaboration, technology, and research</p>
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> <img src={point} style={{ height: "25px" }} />   Mission </b>
                                                        <p className='name'>The Centre for Teaching and Learning at Aditya University is dedicated to improving the quality and effectiveness of teaching across disciplines. We assist professors via thorough professional development, promote new teaching approaches, and foster collaborations with global universities and industry leaders to prepare students for tomorrow's issues. </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default overview;