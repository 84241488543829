import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import ScrollToTop from '../../components/ScrollTop';
import coming_soon from './comongsoon.png';
const comming_soon = () => {

    return (
        <>
            {/* <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            /> */}
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
               
                <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                                <br /><br /><br />
                                            <div class="col-md-12  col-sm-12 col-12" style={{ backgroundColor: "#f8f9fa",marginTop: '255px'}}>
                                                <div class="container">
                                                  <center><img src={coming_soon} /></center>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            {/* <FooterTwo /> */}
        </>
    );
}

export default comming_soon;