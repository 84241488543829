import React, { useState, useEffect } from 'react';
import './Dropdown.css'; // Create a separate CSS file for styles
import downimage from './down.png';


const DropdownExample = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
 

  const handleClickOutside = (event) => {
    if (!event.target.matches('.dropbtn')) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);
 

  return (
    <div>
    <div className="dropdown">
      <button onClick={toggleDropdown} className="dropbtn" style={{ padding: '4px 10px', marginLeft: '109px' }}>
        Login 
        {/* <img src={downimage} alt="Dropdown Icon" style={{ height: "25px" }} /> */}
      </button>
      <div id="myDropdown" className={`dropdown-content ${showDropdown ? 'show' : ''}`}>
        <a className='names' href="https://erp.adityauniversity.in/" target="_blank" rel="noopener noreferrer">
          <b>Student/Parent login</b>
        </a>
        <a className='names' href="https://info.aec.edu.in/aec/olpayment.aspx" target="_blank" rel="noopener noreferrer">
          <b>Online Payment</b>
        </a>
      </div>
    </div>
  </div>
  );
};

export default DropdownExample;
