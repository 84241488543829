import React from 'react';
import { Link } from 'react-router-dom';

// Image
import aboutImg from '../../assets/images/about/ab3.png';
import shapeImg1 from '../../assets/images/about/shape_02.png';
import point from '../../assets/images/icons/image.png';

import Enquire from '../home/Enquire';

import image1 from '../../assets/images/about/programs 2024.png';
import image2 from '../../assets/images/about/scholarship.png';
import image3 from '../../assets/images/about/Ph.D.png';




const AdmissionProcessPart = () => {

    return (
        <div class="about__area about2__area about3__area p-relative pb---60" style={{ marginTop: "-40px" }}>
            <div class="container">
                <div class="row">
                    {/* <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img class="react__shape__1" src={shapeImg1} alt="Shape Image" />
                            <img src={aboutImg} alt="About" />
                        </div>
                    </div> */}

                    <div class="col-lg-12">
                        <div class="about__content">
                           <div>
                           <Enquire />
                                <b class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s" style={{fontSize:"24px"}}>Admission Process :</b>
                                <p className='name'>Aditya University is renowned for its commitment to academic excellence and innovation. Our university has become a leader in engineering education, research, and technology. Aditya offers a dynamic learning environment that fosters intellectual growth, creativity, and the drive to address real-world challenges
                                <br/>
                                B Tech Course aspirants must appear for 10+2 exams or an equivalent exam and must meet the eligibility criteria. The aspirants may have the admission directly or through AP EAPCET. 
                                </p>

                                <b class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s" style={{fontSize:"24px"}}>Direct  Process : </b><br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> Register yourself with valid mail id. 
                                <a href="https://apply.adityauniversity.in/"  style={{ color: "#9a0000" }}><b className="applybuttion1 text page__text content"  data-text="Apply Now !"> Apply Now !</b>
												</a> </span>
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> Verify mail id </span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> Fill the online application form</span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> Submit the application form    </span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> The candidate will be called for counselling based on the merit by the admissions team.</span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> Selected candidates need to pay the prescribed fee whatsoever applicable in full.</span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> The candidates can avail scholarships up to 100% based on their performance in Aditya's Scholastic Aptitude Test (ASAT) or any other national-level engineering entrance exams such as JEE mains. Scholarships are also provided based on 10+2 marks and performance in sports </span> <br/>
                                <br/>
                                <b class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s" style={{fontSize:"24px"}}>Admission through AP EAPCET : </b><br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> Candidates seeking admission through AP EAPCET 2024 must complete their AP EAPCET application process and be successful in EAPCET.</span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> The candidates can choose Aditya University through Counselling Code: <b style={{color:"blue"}}>ADTPPU</b> </span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> After successful allotment, candidates must report to Aditya University</span> <br/>


                                <br/>
                                <b class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s" style={{fontSize:"24px"}}>Eligibility Criteria for B.Tech Admission </b><br/>
                                <p style={{textAlign:"justify"}}>
                                {/* <span className='name'> <img src={point} style={{ height: "25px" }} /> The applicant should be a Resident Indian National </span> <br/> */}
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> Applicants must be at least 16 years old by December 31st of the admission year, with no upper age limit. </span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> Candidates have to be successful in ASAT (Aditya Scholastic Aptitude Test)/ JEE-Main/ State-Level Engineering Entrance Exams across India including EAPCET and Merit in Sports Activities.</span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> Candidates seeking UG Engineering admission must have completed or be appearing in 2024 for one of the specified qualifying examinations. Additionally, a minimum of 60% or equivalent CGPA is required for provisional candidature. </span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} /> Final examination of 10+2 system by State Boards, CBSE, or CISCE with core subjects as given below .</span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} />  Students with Physics, Mathematics & Chemistry/ Computer Science/ Electronics/ Information Technology/ Biology/ Informatics Practices/ Biotechnology/Technical Vocational Subject/ Agriculture/ Engineering Graphics/ Business Studies/ Entrepreneurship are eligible for B.Tech Program in Electrical & Electronics Engineering, Electronics & Communications Engineering, Computer Science & Engineering, Information Technology, Artificial Intelligence & Machine Learning, Data Science.</span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} />  Students with Physics, Chemistry and Mathematics (PCM) are eligible for B.Tech Programs in Civil Engineering, Mechanical Engineering, Petroleum Technology & Mining Engineering .</span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} />  Students with Physics, Chemistry & Mathematics/ Biology/ Biotechnology/ Agriculture/ Agriculture Stream are eligible for B.Tech program in Agriculture Engineering.</span> <br/>
                                <span className='name'> <img src={point} style={{ height: "25px" }} />  Applicants who completed Class 12 (or equivalent) exams outside India or from a non-specified board must provide an AIU certificate confirming equivalence to Class 12, with grades/CGPA converted to percentage. </span> <br/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                
                    <div class="col-lg-12 row" style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <div class="col-lg-3 bsh">
                          <Link to="/programsoffered">  <img src={image1} /> </Link>
                        </div>
                        {/* <div class="col-lg-3 bsh">
                          <Link to="/phd_programs">  <img src={image3} /> </Link>
                        </div> */}
                        <div class="col-lg-3 bsh">
                          <Link to="/fees&scholarship">  <img src={image2} /> </Link>
                         </div>
                    </div>
                 
            </div>
        </div>
    );
}

export default AdmissionProcessPart;