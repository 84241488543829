import React from 'react';
import { Link } from 'react-router-dom';
const Sidebar = () => {
    return (
        <div class="col-md-3 col-sm-3 col-0">
            <div class="side-navbar">
                <nav class="navbar">
                    <ul class="navbar-nav">
                        <li class="navbar-title text-center">
                           <a className="navbar-brand text-light1" href="#" style={{ padding: '12px 121px 12px 131px' }}>Explore</a>
                        </li>
                        {/* <li className={`nav-item ${window.location.pathname === '/diploma_programs' || window.location.pathname === '/programsoffered' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/diploma_programs">Diploma Programs</Link>
                        </li> */}
                        <li className={`nav-item ${window.location.pathname === '/MBA' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/MBA">Overview</Link>
                        </li>
                        {/* <li className={`nav-item ${window.location.pathname === '/MBA_programs_offered' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/MBA_programs_offered"> Programs Offered</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/MBA_faculty' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/MBA_faculty"> Faculty</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/MBA_events' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/MBA_events"> Events</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/MBA_placements' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/MBA_placements"> Placements</Link>
                        </li> */}
                        
                        

                    <br/>
                        
                    </ul>
                </nav>
                    <div className="campus_sec1 row">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <Link to="/school_of_business" className="more-about"  style={{ width: "100%" }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg> Home </Link>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default Sidebar;