import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
    const [submenuVisible, setSubmenuVisible] = useState(false);
    const [activeSubmenu, setActiveSubmenu] = useState('');
    const location = useLocation();

    const toggleSubmenu = () => {
        setSubmenuVisible(!submenuVisible);
    };

    const handleSubmenuClick = (path) => {
        setActiveSubmenu(path);
    };
    return (
        <div class="col-md-3 col-sm-3 col-0">
            <div class="side-navbar">
                <nav class="navbar">
                    <ul class="navbar-nav">
                        <li class="navbar-title text-center">
                           <a className="navbar-brand text-light1" href="#" style={{ padding: '12px 121px 12px 131px' }}>Explore</a>
                        </li>
                        {/* <li className={`nav-item ${window.location.pathname === '/diploma_programs' || window.location.pathname === '/programsoffered' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/diploma_programs">Diploma Programs</Link>
                        </li> */}
                        <li className={`nav-item ${window.location.pathname === '/Mechanical' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/Mechanical">Overview</Link>
                        </li>
                        <li
                            className={`nav-item ${['/Mechanical_UG_program', '/Mechanical_PG_program', '/Mechanical_PHD_program'].includes(location.pathname)  ? 'active' : ''}`}
                            onMouseEnter={toggleSubmenu}
                            onMouseLeave={toggleSubmenu}
                            onClick={toggleSubmenu}
                        >
                            <Link className={`nav-link ${['/Mechanical_UG_program', '/Mechanical_PG_program', '/Mechanical_PHD_program'].includes(location.pathname) || submenuVisible ? 'active' : ''}`} to="#">Programs Offered</Link>
                            {submenuVisible && (
                                <ul className="submenu">
                                    <li
                                        className={`nav-item ${location.pathname === '/Mechanical_UG_program' || activeSubmenu === '/Mechanical_UG_program' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/Mechanical_UG_program')}
                                    >
                                        <Link className="nav-link" to="/Mechanical_UG_program"> B.Tech </Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/Mechanical_PG_program' || activeSubmenu === '/Mechanical_PG_program' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/Mechanical_PG_program')}
                                    >
                                        <Link className="nav-link" to="/Mechanical_PG_program"> M.Tech</Link>
                                    </li>
                                    <li
                                        className={`nav-item ${location.pathname === '/Mechanical_PHD_program' || activeSubmenu === '/Mechanical_PHD_program' ? 'active' : ''}`}
                                        onClick={() => handleSubmenuClick('/Mechanical_PHD_program')}
                                    >
                                        <Link className="nav-link" to="/Mechanical_PHD_program"> Ph.D Program</Link>
                                    </li>
                                </ul>
                            )}
                        </li>
                        {/* <li className={`nav-item ${window.location.pathname === '/Mechanical_faculty' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/Mechanical_faculty"> Faculty</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/Mechanical_events' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/Mechanical_events"> Events</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/Mechanical_placements' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/Mechanical_placements"> Placements</Link>
                        </li> */}
                        
                        

                    <br/>
                        
                    </ul>
                </nav>
                    <div className="campus_sec1 row">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <Link to="/school_of_engineering" className="more-about"  style={{ width: "100%" }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-left"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg> Home </Link>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default Sidebar;