import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import { Dropdown, Container, Row, Col } from 'react-bootstrap';
import "../Styles/menu.css"

const MenuItems = (props) => {
    const { parentMenu } = props;
    const location = useLocation();
    const postURL = location.pathname.split('/');
    const pathLength = Number(postURL.length);

    const [home, setHome] = useState(false);
    const [students, setStudents] = useState(false);
    const [research, setResearch] = useState(false);
    const [community, setCommunity] = useState(false);
    const [blog, setBlog] = useState(false);
    const [megaMenu, setMegaMenu] = useState(false);

    const openMobileMenu = (menu) => {
        setHome(false);
        setStudents(false);
        setResearch(false);
        setCommunity(false);
        setBlog(false);
        setMegaMenu(false);

        if (menu === 'home') setHome(true);
        else if (menu === 'students') setStudents(true);
        else if (menu === 'research') setResearch(true);
        else if (menu === 'community') setCommunity(true);
        else if (menu === 'blog') setBlog(true);
        else if (menu === 'megaMenu') setMegaMenu(true);
    };

    return (
        <>

            <li className={parentMenu === 'home' ? 'has-sub menu-active' : 'has-sub'}>

                <Link to="#" className={home ? 'hash menu-active' : 'hash'} onClick={() => setHome(!home)}>
                    Study<span className='arrow'></span>
                </Link>
                <ul style={{ width: "250px" }} className={home ? 'sub-menu sub-menu-open' : 'sub-menu'}>

                    <div className='row' >
                        <div className='col-lg-12'>
                            <li className='menu-item'>
                                <li className={location.pathname === "/home" ? "menu-active" : ""}>
                                    <Link to="/whyaditya">Why Aditya</Link>
                                </li>
                                <li className={location.pathname === "/home" ? "menu-active" : ""}>
                                    <Link to="/programsoffered">Programs offered</Link>
                                </li>

                                {/* <li className={location.pathname === "/home" ? "menu-active" : ""}>
                                    <Link to="/eligibilitycriteria">Eligibility Criteria</Link>
                                </li> */}
                                <li className={location.pathname === "/home" ? "menu-active" : ""}>
                                    <Link to="/admissionprocess">Admission Process</Link>
                                </li>
                                <li className={location.pathname === "/home" ? "menu-active" : ""}>
                                    <Link to="/hostelfacilities">Hostel Facilities</Link>
                                </li>
                                <li className={location.pathname === "/home" ? "menu-active" : ""}>
                                    <Link to="/fees&scholarship">Fees & Scholarship </Link>
                                </li>
                            </li>
                        </div>
                    </div>
                </ul>
            </li>
            <li className={parentMenu === 'students' ? 'has-sub menu-active' : 'has-sub'}>
                <Link to="#" className={students ? 'hash menu-active' : 'hash'} onClick={() => setStudents(!students)}>
                    Students<span className='arrow'></span>
                </Link>
                <ul style={{ width: "250px" }} className={students ? "sub-menu sub-menu-open" : "sub-menu"}>
                    <div className='row' >
                        <div className='col-lg-12'>
                            <li className='menu-item'>
                                <li className={location.pathname === "/students" ? "menu-active" : ""}>
                                    <Link to="/academics">Academics</Link>
                                </li>
                                <li className={location.pathname === "/students" ? "menu-active" : ""}>
                                    <Link to="/Opportunities">Opportunities</Link>
                                </li>
                                {/* <li className={location.pathname === "/students" ? "menu-active" : ""}>
                                    <Link to="#">Examinations</Link>
                                </li>
                                <li className={location.pathname === "/students" ? "menu-active" : ""}>
                                    <Link to="#">Support</Link>
                                </li>
                                <li className={location.pathname === "/students" ? "menu-active" : ""}>
                                    <Link to="#">Campus Life</Link>
                                </li> */}
                            </li>
                        </div>
                    </div>
                </ul>
            </li>
            <li className={parentMenu === 'research' ? 'has-sub menu-active' : 'has-sub'}>
                <Link to="#" className={research ? 'hash menu-active' : 'hash'} onClick={() => setResearch(!research)}>
                    Research
                    <span className='arrow'></span>
                </Link>
                <ul style={{ width: "250px" }} className={research ? "sub-menu sub-menu-open" : "sub-menu"}>
                    <div className='row' >
                        <div className='col-lg-12'>
                            <li className='menu-item'>
                                <li className={location.pathname === "/research" ? "menu-active" : ""}>
                                    <Link to="/OurResearch"> Our Research</Link>
                                </li>
                                <li className={location.pathname === "/research" ? "menu-active" : ""}>
                                    <Link to="/Patents_Commercialisation">Patents & Commercialisation</Link>
                                </li>
                                {/* <li className={location.pathname === "/research" ? "menu-active" : ""}>
                                    <Link to="#">Research Endeavours & Collaboration</Link>
                                </li> */}
                            </li>
                        </div>
                    </div>
                </ul>
            </li>

            <li className={parentMenu === 'community' ? 'has-sub menu-active' : 'has-sub'}>
                <Link to="#" className={community ? 'hash menu-active' : 'hash'} onClick={() => setCommunity(!community)}>
                    Outreach
                    <span className='arrow'></span>
                </Link>
                <ul style={{ width: "300px" }} className={community ? "sub-menu sub-menu-open" : "sub-menu"}>
                    <div className='row' >
                        <div className='col-lg-12'>
                            <li className='menu-item'>
                                <li className={location.pathname === "/community" ? "menu-active" : ""}>
                                    <Link to="/collaborations">Collaborations</Link>
                                </li>
                                <li className={location.pathname === "/community" ? "menu-active" : ""}>
                                    <Link to="/alumni">Alumni</Link>
                                </li>
                                <li className={location.pathname === "/community" ? "menu-active" : ""}>
                                    <Link to="/community_activities">Community Activities</Link>
                                </li>
                                <li className={location.pathname === "/community" ? "menu-active" : ""}>
                                    <Link to="/aditya_learning_academy">Aditya Learning Academy</Link>
                                </li>
                            </li>
                        </div>
                    </div>
                </ul>
            </li>
            <li className={megaMenu ? 'has-sub menu-active' : 'has-sub'}>
                <Link to="#" className={megaMenu ? 'hash menu-active' : 'hash'} onClick={() => setMegaMenu(!megaMenu)}>
                    About
                    <span className="arrow"></span>
                </Link>
                <ul className={megaMenu ? 'sub-menu sub-menu-open mega-sub-menu' : 'sub-menu mega-sub-menu'}>
                    <div className='row' >
                        <div className='col-lg-4'>
                            <li>
                                {/* <h3>Test 1</h3> */}
                                <ul>
                                    <li className="text-primary">
                                        Aditya University
                                    </li>
                                    <li>
                                        <Link to="/overview">Overview</Link>
                                    </li>
                                    <li>
                                        <Link to="/visionmission">Vision and Mission</Link>
                                    </li>
                                    <li>
                                        <Link to="/corevalues">Core Values</Link>
                                    </li>
                                    <li>
                                        <Link to="/Chancellor">Chancellor's Message</Link>
                                    </li>
                                    <li>
                                        <Link to="/Pro_Chancellor">Pro-Chancellor's Message</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Vice Chancellor's Message</Link>
                                    </li>
                                </ul>
                            </li>
                        </div>
                        <div className='col-lg-4'>
                            <li>
                                <ul>
                                    <li className="text-primary">
                                        Governance
                                    </li>
                                    <li>
                                        <Link to="#">Governing Body</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Board of Management</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Academic Council</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Finance Committee</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Organogram</Link>
                                    </li>
                                </ul>
                            </li>
                        </div>
                        <div className='col-lg-4'>
                            <li>
                                <ul>
                                    <li className="text-primary">
                                        Credentials
                                    </li>
                                    <li>
                                        <Link to="/accreditations">Accreditations , Recognitions & Rankings</Link>
                                    </li>
                                    {/* <li>
                                        <Link to="#">Rankings</Link>
                                    </li> */}
                                </ul>
                            </li>
                        </div>

                    </div>
                </ul>
            </li>
            
                
           

        </>
    );
};

export default MenuItems;
