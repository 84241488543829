import React, { useState } from 'react';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../components/ScrollTop';
import "../../../../components/Styles/pragram.css"
import point from '../../../../assets/images/icons/image.png';
import SideManu from './sidebar_management';
import Linkimage from './images/link.gif';
import MBAimage from './images/mba.png';
import IMBAimage from './images/imba.png';

import MBApdf from './images/mba.pdf';
import IMBApdf from './images/imba.pdf';






const Curriculum = () => {
    const [activeIndex, setActiveIndex] = useState(0); // Initialize with 0 to make the first item open by default
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                   <StudyBreadcrumb pageTitle="School of Business " />
                   <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 " style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                    {/* <div class="container">
                                                       <button className={`accordion active ${activeIndex === 0 ? 'active' : ''}`} onClick={() => toggleAccordion(0)}>
                                                           <img src={MBAimage} style={{ height: '40px' }} />&nbsp;&nbsp;&nbsp;&nbsp;Curriculum
                                                           <span className="symbol">{activeIndex === 0 ? '▲' : '▼'}</span>
                                                       </button>
                                                       <div className="panel" style={{ display: activeIndex === 0 ? 'block' : 'block' }}>
                                                           <br />
                                                           <table style={{ width: "100%" }}>
                                                               <thead>
                                                                   <tr>
                                                                       <th style={{ textAlign: 'center' }}>Program</th>
                                                                       <th style={{ textAlign: 'center' }}>2024</th>
                                                                   </tr>
                                                               </thead>
                                                               <tbody>
                                                                   <tr>
                                                                       <td>MBA (Curriculum)</td>
                                                                       <td><a href={MBApdf} target="_blank" style={{ color: "#000" }} className='namehover'> Curriculum </a><img src={Linkimage} style={{ height: '25px' }} /></td>
                                                                   </tr>
                                                               </tbody>
                                                           </table>
                                                           <br />
                                                       </div>
                                                    </div> */}
                                                    <div className="container">
                                                    <div className="about__content">
                                                        <div>
                                                            {[
                                                                { id: 0, title: 'MBA',title1: 'Master of Business Administration', image: MBAimage, pdf: MBApdf },
                                                                { id: 1, title: 'IMBA',title1: 'International Masters Degree in Business Administration', image: IMBAimage, pdf: IMBApdf },
                                                             ].map(({ id, title,title1, image, pdf }) => (
                                                                <React.Fragment key={id}>
                                                                    <button className={`accordion ${activeIndex === id ? 'active' : ''}`} onClick={() => toggleAccordion(id)}>
                                                                        <img src={image} style={{ height: '40px' }} alt={title} />&nbsp;&nbsp;&nbsp;&nbsp;{title}
                                                                        <span className="symbol">{activeIndex === id ? '▲' : '▼'}</span>
                                                                    </button>
                                                                    <div className="panel" style={{ display: activeIndex === id ? 'block' : 'none' }}>
                                                                        <br />
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ textAlign: 'center' }}>Program</th>
                                                                                    <th style={{ textAlign: 'center' }}>2024</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td> {title} ({title1})</td>
                                                                                    <td>
                                                                                        <a href={pdf} target="_blank" style={{ color: "#000" }} className='namehover'> Curriculum </a>
                                                                                        <img src={Linkimage} style={{ height: '25px' }} alt="link icon" />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <br />
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            <FooterTwo />

        </>
    );
}

export default Curriculum;