import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/aditya_learning_academy';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_learning_academy';
import EventfileAI from '../../../assets/images/aditya_learning_academy/mchine_learning.pdf';
import image1 from './Images/left.gif';




import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


const Eligibility = (props) => {
    let tab1 = "Completed Events",
        tab2 = "Ongoing Event",
        tab3 = "Upcoming Event"
    const tabStyle = 'nav nav-tabs';


    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        const status = JSON.parse(localStorage.getItem('event_status'));
        if (status === 1) {
            setSelectedIndex(2); // Tab3
        } else {
            setSelectedIndex(0); // Tab1
        }
    }, [status]);
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div className="back__course__page_grid react-courses__single-page pb---16 ">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Aditya Learning Academy" pageName="Events" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div class="container text-justify">
                                                    <br />

                                                    <Tabs selectedIndex={selectedIndex} onSelect={index => setSelectedIndex(index)}>
                                                        <div className="course-single-tab">
                                                            <TabList className={tabStyle}>
                                                                <Tab className='col-md-4'><button>{tab1}</button></Tab>
                                                                <Tab className='col-md-4'><button>{tab2}</button></Tab>
                                                                <Tab className='col-md-4'><button>{tab3}</button></Tab>
                                                            </TabList>

                                                            <div>
                                                                <TabPanel>
                                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Completed Events</b> <br />
                                                                    <p style={{ textAlign: "justify" }}>
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> 1.A Five Day Sensitization program on “Outcome Based Education (OBE)” is scheduled from 10th to 14th June 2024, organized by IQAC in association with ALA </span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> 2.A Five Day FDP on “Joy of Computing using Python” is scheduled from 2nd to 6th July 2024, organized by the Department of AIML in association with ALA. </span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> 3.A Five Day FDP on “Data Analytics Using Microsoft Power BI” is scheduled from 8th to 12th July 2024, organized by the Department of IT in association with ALA </span><br />
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> 4.An online Certification course on "Machine Learning and GenAI" is scheduled from 05th to 16th August 2024, organized by IEduVibhu in association with ALA. </span><br />
                                                                    </p>
                                                                    <br />
                                                                </TabPanel>
                                                                <TabPanel>
                                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Ongoing Event </b> <br />
                                                                    <p style={{ textAlign: "justify" }}>
                                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> 1.	A Five Day FDP on “Innovative Teaching Methodologies” is scheduled from 22nd to 26th July 2024, organized by IQAC in association with ALA</span> <br />
                                                                    </p>
                                                                    <br />
                                                                </TabPanel>
                                                                <TabPanel>
                                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Upcoming Event </b> <br />
                                                                    {/* <p style={{ textAlign: "justify" }}>
                                                                        <Link to={EventfileAI} target="blank"> <span className='name namehover' style={{ color: "#000" }}> <img src={point} style={{ height: "25px" }} /> 1.An online Certification course on "Machine Learning and GenAI" is scheduled from 05th to 16th August 2024, organized by IEduVibhu in association with ALA.<img src={image1} style={{ height: "25px" }} /> </span> </Link><br />
                                                                    </p> */}
                                                                    <br />
                                                                </TabPanel>

                                                            </div>
                                                        </div>
                                                    </Tabs>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
            <FooterTwo />
        </>
    );
}

export default Eligibility;