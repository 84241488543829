import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/international';
import ScrollToTop from '../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import '../../../components/Styles/pragram.css';
import point from '../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';

import SideManu from './sidebar_opportunities';



const diploma_programs = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Contact Us" />

                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">

                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">

                                                    <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}> Contact us :</b> <br /><br />
                                                    {/* <span className='name'><img src={point} style={{height:"25px"}}/> tpo@adityauniversity.in </span><br/> */}
                                                    {/* <span className='name'><img src={point} style={{height:"25px"}}/> coming soon </span><br/> */}
                                                        <div className='row'>
                                                            <div class="col-md-6">
                                                                <span className='name'><img src={point} style={{ height: "25px" }} /><b> Head Placements</b> </span>
                                                                <br />
                                                                <span className='name'>Name   : Mr. V. Naga Raju</span><br />
                                                                <span className='name'>Phone  : +91 70361 76668</span><br />
                                                                <span className='name'>Email  :   nagaraju@aditya.ac.in</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <span className='name'><img src={point} style={{ height: "25px" }} /><b>  Manager-Industry Relations & Placements</b> </span>
                                                                <br />
                                                                <span className='name'>Name   : Mrs. K.Vasavi </span><br />
                                                                <span className='name'>Phone  : +91 92905 49747</span><br />
                                                                <span className='name'>Email  :  vasavi@aditya.ac.in</span>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div className='row'>
                                                            <div class="col-md-6">
                                                                <span className='name'><img src={point} style={{ height: "25px" }} /><b> Placement Officer</b> </span>
                                                                <br />
                                                                <span className='name'>Name   : Mr. MD.Jafarali</span><br />
                                                                <span className='name'>Phone  : +91 76609 16662</span><br />
                                                                <span className='name'>Email  :  jafar@aditya.ac.in</span>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <span className='name'><img src={point} style={{ height: "25px" }} /><b>  Placement Coordinator</b> </span>
                                                                <br />
                                                                <span className='name'>Name   : Mr. G. Hari Krishna</span><br />
                                                                <span className='name'>Phone  :  +91 96181 76664 , +91 99664 39688 </span><br />
                                                                <span className='name'>Email  :  harikrishna@aditya.ac.in,&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;tpo@adityauniversity.in</span>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div className='row'>
                                                            <div class="col-md-6">
                                                                <span className='name'><img src={point} style={{ height: "25px" }} /><b> Placement Coordinator</b> </span>
                                                                <br />
                                                                <span className='name'>Name   : Mr. L. Suresh</span><br />
                                                                <span className='name'>Phone  : +91 97040 76661</span><br />
                                                                <span className='name'>Email  :  suresh.lanka@adityauniversity.in</span>
                                                            </div>
                                                        </div>

                                                        <br />
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* scrolltop-start */}
                        <ScrollToTop />
                        {/* scrolltop-end */}
                    </div>
                </div>

                <FooterTwo />

            </>
            );
}

            export default diploma_programs;