import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './popupcss.css';
import internshipImage from './images/veda_day_1.jpg';


// Modal.setAppElement('#root'); // this is necessary for accessibility reasons

const Popup = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    setModalIsOpen(true); // Automatically open the modal when the component mounts
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Modal 
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal" 
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '9999',
         
          padding:'0px',
          backgroundcolor: 'rgb(90 86 86 / 75%)',
      
          
        },
     }}
     
    >
    
      <img src={internshipImage} className='mobilepopup' />
      <button className='close-btn' style={{marginLeft: '-70px',marginTop:'-5px'}} onClick={closeModal}>Close</button>
      
    </Modal>
  );
};

export default Popup;
