import React from 'react';
import { useState } from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

import AGRIPopUp from "../../../../../components/Popup/Agricultural/AgriculturalPopUp";
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_agri';
import Banner from './banner';





const CEview = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    <AGRIPopUp />

                    
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> B.Tech <i class="fas fa-treasure-chest    "></i></b> </center><br />
                                                    <div class="about__content">
                                                    
                                                        <p className='name'>Established at Aditya University in 2014, the department of agricultural engineering is dedicated to nurturing future pioneers and leaders in the agricultural technology sector. Our undergraduate B.Tech program, with an intake capacity of 60 students, provides a comprehensive education combining theoretical knowledge and practical experience. This ensures our graduates are well-prepared to address real-world agricultural challenges.<br></br>
                                                        </p>
                                                        <p className='name'>Our curriculum emphasizes sustainable practices, including energy-efficient farming techniques, precision agriculture, and the development of eco-friendly agricultural technologies. We focus on minimizing the environmental impact of farming through sustainable water management, soil conservation, and the integration of renewable energy sources. Additionally, we cover agricultural processing, post-harvesting, value addition, dairy and food engineering, and agricultural structures.
                                                        </p>
                                                        <p className='name'>The program promotes the use of advanced farm implements and machinery to enhance mechanization. Adopting a multidisciplinary approach, we incorporate IoT, sensors, drone technology, and image processing for pest and disease detection. This holistic approach equips our students with the knowledge and skills necessary to tackle pressing agricultural and environmental challenges, fostering a new generation of innovative and environmentally responsible agricultural engineers.</p>
                                                        <p className='name'>A key component of the Agricultural Engineering curriculum is the focus on climate resilient farming. By incorporating measures to adapt and mitigate the effects of climate change, agricultural engineers can contribute significantly to building a more sustainable and secure food future for all. Students learn to develop innovative solutions that enhance agricultural productivity while preserving natural resources and protecting the environment.</p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> Core Curriculum:</b>
                                                        <p className='name'>The Agricultural Engineering program ensures a solid scientific and analytical foundation through major core courses, including: 
                                                        <br></br>
                                                       <b > Farm Machinery and Power Engineering: </b>
                                                        Principles and applications of farm machinery and power units.<br></br>

                                                        <b > Soil and Water Engineering: </b>
                                                        Techniques for efficient water use and soil conservation.<br></br>

                                                        <b >  Agricultural Process Engineering:  </b>
                                                        Designing and managing irrigation and drainage systems.<br></br>
                                                        
                                                        <b >  Irrigation and Drainage Engineering:  </b>
                                                        Postharvest technology and food processing methods.<br></br>
                                                        
                                                        <b > Renewable Energy in Agriculture:  </b>
                                                        Utilization of renewable energy sources in farming.<br></br>
                                                        
                                                        <b > Environmental Engineering:  </b>
                                                        Addressing environmental issues in agricultural practices.<br></br>
                                                        
                                                        <b > Precision Agriculture: </b>
                                                        Use of advanced technologies for precise farming practices.<br></br>

                                                        <b >Mathematics and Statistics: </b>
                                                        Providing the mathematical foundation for engineering problem-solving.<br></br><br></br></p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> Highlight:</b>

                                                        <p className='name'><b >ICAR UG Curriculum Compliance: </b>
                                                       The program follows the Indian Council of Agricultural Research (ICAR) Undergraduate Curriculum, ensuring that the education provided meets national standards for agricultural education and prepares students for a variety of professional opportunities.<br></br>Students can achieve advanced technical expertise in one or more areas of concentration by selecting appropriate ability and skill enhancement courses, and value-added courses. Alternatively, students have the flexibility to broaden their academic experience by taking elective courses from other departments, fostering a multidisciplinary approach to their education.</p>

                                                       <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> Practical Experience:</b>
                                                        <p className='name'>The program places a strong emphasis on practical, hands-on experience through:
                                                        <br></br>

                                                        <b > Laboratory Work: </b>
                                                        State-of-the-art laboratories for testing and research in soil, water, machinery, and food processing.<br></br>

                                                        <b >Field Work:  </b>
                                                        Real-world applications and field projects to practice and refine technical skills.<br></br>

                                                        <b >Internships:   </b>
                                                        Opportunities to work with leading agricultural companies, research institutions, and government agencies.</p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> Career Opportunities:</b>
                                                        <p className='name'>Graduates of the Agricultural Engineering program are well prepared for diverse career paths, including:</p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <img src={point1} style={{ height: "25px" }} /> Agricultural machinery design and manufacturing<br/>
                                                            <img src={point1} style={{ height: "25px" }} /> Irrigation and water resource management<br/>
                                                            <img src={point1} style={{ height: "25px" }} /> Sustainable farming consultancy<br/>
                                                            <img src={point1} style={{ height: "25px" }} /> Agricultural research and development<br/>
                                                            <img src={point1} style={{ height: "25px" }} /> Food processing industries<br/>
                                                            <img src={point1} style={{ height: "25px" }} /> Renewable energy projects in agriculture<br/>
                                                            <img src={point1} style={{ height: "25px" }} /> Banking Sector (AFO)<br/>
                                                        </p> 
                                                       
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />   B.Tech in (Agricultural Engineering)<br></br>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech in (Agricultural Engineering) with minor degree in 
                                                            <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <br/>
                                                            <img src={point1} style={{ height: "25px" }} /> Automation & Robotics<br/>
                                                            <img src={point1} style={{ height: "25px" }} /> Entrepreneurship Development & Incubation.<br/>
                                                            </p>
                                                        </p>   
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;