import React, { useState, useEffect } from 'react';
import NIRF from './scrolling/NIRF.png'; 
import NAAC from './scrolling/NAAC.png';
import NBA from './scrolling/NBA.png';


const Marquee = () => {
  const images = [
    { src: NAAC, alt: 'NAAC Logo' },
    { src: NIRF, alt: 'NIRF Logo' },
    { src: NBA, alt: 'NBA Logo' },

  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // 3 seconds interval

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div style={styles.marqueeContainer} className='nirflogo'>
      <img
        src={images[currentImageIndex].src}
        alt={images[currentImageIndex].alt}
        style={styles.image}
      />
    </div>
  );
};

const styles = {
  marqueeContainer: {
    height: '80px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    float:'right',
  },
  image: {
    height: '80px',
    transition: 'transform 2s ease-in-out',
    padding: '10px',
  },
};

export default Marquee;
