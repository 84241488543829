import React from 'react';
import { Link } from 'react-router-dom';

// Image
import aboutImg from '../../assets/images/about/hostel.jpg';
import shapeImg1 from '../../assets/images/about/shape_02.png';
import point from '../../assets/images/icons/image.png';


const HostelPart = () => {

    return (
        <div class="about__area about2__area about3__area p-relative pb---60" style={{marginTop:"40px"}}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp img-change" data-wow-duration="0.3s">
                            <img class="react__shape__1" src={shapeImg1} alt="Shape Image" />
                            <img src={aboutImg} alt="About" width="80%" style={{marginTop:"100px"}}/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about__content">
                            <div>
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Hostel Facilities</h2>
                            <ul>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Hostel Fee: 95000/- per year for Non-AC Accommodation & 105000/- per year for AC Accommodation (Includes Laundry expenses & Medical Insurance coverage of 200000/-).</li>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.5s"><img src={point} style={{ height: "25px" }} /> For all type of rooms, Electric power charges to be paid as per individual consumption. </li>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.5s"><img src={point} style={{ height: "25px" }} /> Rooms are allotted on First-Come-First serve basis and subject to availability</li>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.5s"><img src={point} style={{ height: "25px" }} /> Round the Clock (24*7) Ambulance Facility.</li>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.5s"><img src={point} style={{ height: "25px" }} /> AC & non-AC 3-Sharing Accommodation with attached Bath & Balcony.</li>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.5s"><img src={point} style={{ height: "25px" }} /> 24*7 CCTV Surveillance.</li>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.5s"><img src={point} style={{ height: "25px" }} /> Uninterrupted Power Supply.</li>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.5s"><img src={point} style={{ height: "25px" }} /> Women Security in Women’s Hostels.</li>

                            </ul>
                            </div>
                            {/* <div class="about__btn wow animate__fadeInUp" data-wow-duration="0.7s">
                                <Link to="/about"> Read More </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HostelPart;