import React from 'react';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import AboutBreadcrumb from '../../components/Breadcrumb/about';
import ScrollToTop from '../../components/ScrollTop';


import Logo from '../../assets/images/logos/logo2.png';

const CoreValues = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            // headerNormalLogo={Logo}
            // headerStickyLogo={Logo}
            />

            <div class="react-wrapper">
                <div class="react-wrapper-inner">

                    <AboutBreadcrumb pageTitle="Board of Management" />
                    <div class="col-md-12  col-sm-12 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                        <div class="container" style={{ width: '65%' }}>



                            <div class="table-responsive table-bordered table-striped ">

                                <table class="table responsive">
                                    <thead>
                                        <tr>
                                            <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center" }}>S.No</th>
                                            <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center", width: '25%' }}>Name</th>

                                            <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center" }}>Designation</th>
                                            <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center", width: '26%' }}>Position in the committee</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'center' }}>1</td>
                                            <td >Dr. M. B. Srinivas</td>
                                            <td className='center'>Vice-Chancellor</td>
                                            <td className='center'>Ex-officio Chairperson</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>2</td>
                                            <td >Dr. K. V. Jaya Kumar</td>
                                            <td className='center'>Visiting Professor-cum-Outreach Advisor, IIT Dharwad</td>
                                            <td className='center'>Nominated member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>3</td>
                                            <td >Dr. N. C. Siva Prakash</td>
                                            <td className='center'>Professor,Dept of Instrumentation and Applied Physics,IISc, Bangalore </td>
                                            <td className='center'>Nominated member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>4</td>
                                            <td >Mrs. N. Sruthi Reddy</td>
                                            <td className='center'>Member, Aditya Academy, Kakinada </td>
                                            <td className='center'>Nominated member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>5</td>
                                            <td >Dr. N. Suguna Reddy</td>
                                            <td className='center'>Joint Secretary, Sarojini Educational Society, Kakinada </td>
                                            <td className='center'>Nominated member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>6</td>
                                            <td >Dr. K. V. S. R. Murthy</td>
                                            <td className='center'>Professor in EEE & Dean – R&C </td>
                                            <td className='center'>Ex-officio Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>7</td>
                                            <td >Dr. G. Ramakrishna</td>
                                            <td className='center'>Professor in ECE & Dean – IQAC</td>
                                            <td className='center'>Ex-officio Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>8</td>
                                            <td >Dr. A. Vanathi</td>
                                            <td className='center'>Professor in CSE & Assoc. Dean – Freshmen Engineering.</td>
                                            <td className='center'>Ex-officio Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>9</td>
                                            <td >Dr. A. Saravanan</td>
                                            <td className='center'>Professor & HoD, Dept. of ME, School of Engineering </td>
                                            <td className='center'>Ex-officio Member</td>
                                        </tr>
                                        <tr>
                                            <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>10</td>
                                            <td >Dr. G. Suresh</td>
                                            <td className='center'>Registrar</td>
                                            <td className='center'>Ex-officio Member Secretary </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                    {/* scrolltop-start */}
                    <ScrollToTop />
                    {/* scrolltop-end */}
                </div>
            </div>

            <FooterTwo />

        </>
    );
}

export default CoreValues;