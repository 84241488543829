import React from 'react';
import { Link } from 'react-router-dom';

// Image
import aboutImg from '../../assets/images/about/ab3.png';
import shapeImg1 from '../../assets/images/about/shape_02.png';
import point from '../../assets/images/icons/image.png';


const VisionMissionPart = () => {

    return (
        <div class="about__area about2__area about3__area p-relative pb---60" style={{marginTop:"40px"}}>
            <div class="container">
                <div class="row">
                    {/* <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img class="react__shape__1" src={shapeImg1} alt="Shape Image" />
                            <img src={aboutImg} alt="About" />
                        </div>
                    </div> */}
                    <div class="col-lg-12">
                        <div class="about__content">
                            {/* <h6 className="wow animate__fadeInUp" data-wow-duration="0.3s">Vision</h6> */}
                            <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Vision</h2>
                            <p class="about__paragraph wow animate__fadeInUp" data-wow-duration="0.7s">Aditya University aspires to be a globally recognised academic institution dedicated to quality education, cutting-edge research, and technological service to our country, and envisions itself as a beacon of holistic advancement and long-term impact, remaining dynamic in the ever-changing worlds of society, ecology, and economics..</p>
                            <div>
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Mission</h2>
                            <ul>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Aditya University pushes boundaries to design high-quality curricula and to provide students with a vibrant and relevant education that prepares them for a changing world. Our industry insights and creative teaching methods attempt to equip our students to be lifelong learners.</li>
                                <li className=" mission wow animate__fadeInUp" data-wow-duration="0.5s"><img src={point} style={{ height: "25px" }} />  Aditya University's learning environment encourages intellectual curiosity, critical thinking, and cooperation, with the goal of providing students with an immersive education that fosters creativity and innovation. Our cutting-edge facilities, interactive classrooms, and supportive faculty aim to motivate students to realise their full potential and contribute to society. </li>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.5s"><img src={point} style={{ height: "25px" }} />  Aditya University promotes cross-disciplinary inquiry and discovery and leads cutting-edge research and innovation. Through strategic partnerships, research grants, and a dedicated faculty, we aim to advance science, technology, and social sciences and empower students and faculty to conduct transformative research that solves real-world problems and elevates our institution globally. </li>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.5s"><img src={point} style={{ height: "25px" }} />  Aditya University is committed to producing world-changing business leaders and entrepreneurs through its emphasis on entrepreneurship, mentoring, and business incubation programmes.</li>
                            </ul>
                            </div>
                            {/* <div class="about__btn wow animate__fadeInUp" data-wow-duration="0.7s">
                                <Link to="/about"> Read More </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VisionMissionPart;