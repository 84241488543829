import React, { useState } from 'react';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../components/ScrollTop';
import "../../../../components/Styles/pragram.css"
import point from '../../../../assets/images/icons/image.png';
import SideManu from './sidebar_eng';
import Linkimage from './images/link.gif';
import Academic_calendar_image from './images/academic_calendar/calendar.png';
import B_tech from './images/academic_calendar/b_tech_2024.pdf';
import M_tech from './images/academic_calendar/m_tech_2024.pdf';
import MCA from './images/academic_calendar/mca_2024.pdf';







const Curriculum = () => {
    const [activeIndex, setActiveIndex] = useState(0); // Initialize with 0 to make the first item open by default
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                   <StudyBreadcrumb pageTitle="School of Business " />
                   <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 " style={{ backgroundColor: "#f8f9fa" }}>
                                            <br />
                                                    <div class="container">
                                                    <div>
                                                            {[
                                                                { id: 0, title: 'B.Tech',title1: 'Bachelor of Technology', image: Academic_calendar_image, pdf: B_tech },
                                                                { id: 1, title: 'M.Tech',title1: 'Masters of Technology', image: Academic_calendar_image, pdf: M_tech },
                                                                { id: 2, title: 'MCA',title1: 'Master of Computer Application', image: Academic_calendar_image, pdf: MCA },
                                                            ].map(({ id, title,title1, image, pdf }) => (
                                                                <React.Fragment key={id}>
                                                                    <button className={`accordion ${activeIndex === id ? 'active' : ''}`} onClick={() => toggleAccordion(id)}>
                                                                        <img src={image} style={{ height: '40px' }} alt={title} />&nbsp;&nbsp;&nbsp;&nbsp;{title}
                                                                        <span className="symbol">{activeIndex === id ? '▲' : '▼'}</span>
                                                                    </button>
                                                                    <div className="panel" style={{ display: activeIndex === id ? 'block' : 'none' ,marginBottom:'14px'}}>
                                                                        <br />
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ textAlign: 'center' }}>Program</th>
                                                                                    <th style={{ textAlign: 'center' }}>2024-25</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{title} ({title1})</td>
                                                                                    <td>
                                                                                        <a href={pdf} target="_blank" style={{ color: "#000" }} className='namehover'> Academic calendar </a>
                                                                                        <img src={Linkimage} style={{ height: '25px' }} alt="link icon" />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <br />
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            <FooterTwo />

        </>
    );
}

export default Curriculum;