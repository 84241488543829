import React from 'react';
import { Link } from 'react-router-dom';

// Image
import aboutImg from '../../assets/images/about/ab3.png';
import shapeImg1 from '../../assets/images/about/shape_02.png';
import point from '../../assets/images/icons/image.png';


const CoreValuesPart = () => {

    return (
        <div class="about__area about2__area about3__area p-relative pb---60" style={{marginTop:"40px"}}>
            <div class="container">
                <div class="row">
                    
                    <div class="col-lg-12">
                        <div class="about__content" >
                            <div>
                                <h2 class="about__title wow animate__fadeInUp text-danger" data-wow-duration="0.5s">Core Values</h2>
                            <ul>
                                <h6 className="wow animate__fadeInUp" data-wow-duration="0.3s">Excellence</h6>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> We hold ourselves to the highest standards in all aspects of education, research, and community engagement.</li>
                                <h6 className="wow animate__fadeInUp" data-wow-duration="0.3s">Inclusivity and Diversity</h6>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> We value diversity and provide an inclusive environment where people from all background’s perspectives, and experiences learn, cooperate, and grow. We believe a varied academic atmosphere enhances education for all.</li>
                                <h6 className="wow animate__fadeInUp" data-wow-duration="0.3s">Integrity and Ethical Conduct</h6>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> Our academic community is built on integrity. We do ethical research, teaching, and interactions. All university staff and students are valued for their honesty, transparency, and accountability, which helps to foster a trustworthy and accountable environment.</li>
                                <h6 className="wow animate__fadeInUp" data-wow-duration="0.3s">Global Outlook</h6>
                                <li className="mission wow animate__fadeInUp" data-wow-duration="0.3s"><img src={point} style={{ height: "25px" }} /> We advocate     for global knowledge, intercultural understanding, and social responsibility. Our graduates have the ability to solve problems from a global perspective.</li>
                                
                            </ul>
                            </div>
                            {/* <div class="about__btn wow animate__fadeInUp" data-wow-duration="0.7s">
                                <Link to="/about"> Read More </Link>
                            </div> */}
                        </div>
                    </div>
                    {/* <div class="col-lg-6">
                        <div class="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img class="react__shape__1" src={shapeImg1} alt="Shape Image" />
                            <img src={aboutImg} alt="About" />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default CoreValuesPart;