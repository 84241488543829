import React, { useState } from 'react';
import Header from '../../../../components/Header';
import FooterTwo from '../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
// import point from '../../../../assets/images/icons/image.png';
import "../../../../components/Styles/pragram.css"
import SideManu from './sidebar_eng';
import EEEimage from './images/eee.png';
import Cvimage from './images/cv.png';
import Mechimage from './images/mech.png';
import Eceimage from './images/ece.png';
import Cseimage from './images/cse.png';
import ITimage from './images/it.png';
import AIMLimage from './images/aiml.png';
import DSimage from './images/ds.png';
import PTimage from './images/pt.png';
import AGEimage from './images/age.png';
import Miningimage from './images/mining.png';

import Linkimage from './images/link.gif';

import Clpdf from './images/civil.pdf';
import EEEpdf from './images/eee.pdf';
import Mechpdf from './images/mech.pdf';
import ECEpdf from './images/ece.pdf';
import Csepdf from './images/cse.pdf';
import ITpdf from './images/it.pdf';
import AIMLpdf from './images/aiml.pdf';
import DSpdf from './images/ds.pdf';
import PTpdf from './images/pt.pdf';
import Miningpdf from './images/mining.pdf';
import AGEpdf from './images/age.pdf';

const Engoverview = () => {
    const [activeIndex, setActiveIndex] = useState(0); // Initialize with 0 to make the first item open by default
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <>
            <Header parentMenu='page' menuCategoryEnable='enable' />
            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="School of Engineering" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div className="research" id="">
                                    <div className="container">
                                        <div className="row m-0">
                                            <SideManu />
                                            <div className="col-md-1 col-sm-1 col-2"></div>
                                            <div className="col-md-8 col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div className="container">
                                                    <div className="about__content">
                                                        <div>
                                                            {[
                                                                { id: 0, title: 'Civil Engineering', image: Cvimage, pdf: Clpdf },
                                                                { id: 1, title: 'Electrical and Electronics Engineering', image: EEEimage, pdf: EEEpdf },
                                                                { id: 2, title: 'Mechanical Engineering', image: Mechimage, pdf: Mechpdf },
                                                                { id: 3, title: 'Electronics and Communication Engineering', image: Eceimage, pdf: ECEpdf },
                                                                { id: 4, title: 'Computer Science and Engineering', image: Cseimage, pdf: Csepdf },
                                                                { id: 5, title: 'Information Technology', image: ITimage, pdf: ITpdf },
                                                                { id: 6, title: 'Artificial Intelligence and Machine Learning', image: AIMLimage, pdf: AIMLpdf },
                                                                { id: 7, title: 'Data Science', image: DSimage, pdf: DSpdf },
                                                                { id: 8, title: 'Petroleum Technology', image: PTimage, pdf: PTpdf },
                                                                { id: 9, title: 'Mining Engineering', image: Miningimage, pdf: Miningpdf },
                                                                { id: 10, title: 'Agricultural Engineering', image: AGEimage, pdf: AGEpdf },
                                                            ].map(({ id, title, image, pdf }) => (
                                                                <React.Fragment key={id}>
                                                                    <button className={`accordion ${activeIndex === id ? 'active' : ''}`} onClick={() => toggleAccordion(id)}>
                                                                        <img src={image} style={{ height: '40px' }} alt={title} />&nbsp;&nbsp;&nbsp;&nbsp;{title}
                                                                        <span className="symbol">{activeIndex === id ? '▲' : '▼'}</span>
                                                                    </button>
                                                                    <div className="panel" style={{ display: activeIndex === id ? 'block' : 'none' ,marginBottom:'14px'}}>
                                                                        <br />
                                                                        <table style={{ width: "100%" }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th style={{ textAlign: 'center' }}>Program</th>
                                                                                    <th style={{ textAlign: 'center' }}>2024</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>B.Tech ({title})</td>
                                                                                    <td>
                                                                                        <a href={pdf} target="_blank" style={{ color: "#000" }} className='namehover'> Curriculum </a>
                                                                                        <img src={Linkimage} style={{ height: '25px' }} alt="link icon" />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <br />
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />
        </>
    );
}

export default Engoverview;
