import React from 'react';
import { Link } from 'react-router-dom';
const Sidebar = () => {
    return (
        <div class="col-md-3 col-sm-3 col-0">
            <div class="side-navbar">
                <nav class="navbar">
                    <ul class="navbar-nav">
                         <li className="navbar-title text-center">
                            <a className="navbar-brand text-light1" href="#" style={{ padding: '12px 121px 12px 131px' }}>Explore</a>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/accreditations' || window.location.pathname === '/accreditations' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/accreditations">Accreditations</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/recognitions' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/recognitions"> Recognitions</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/rankings' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/rankings"> Rankings</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/nirf' ? 'active' : ''}`}>
                            <Link class="nav-link " to="/nirf"> NIRF</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Sidebar;