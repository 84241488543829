import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
// import point1 from '../../../assets/images/icons/image1.png';
// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_mba';



const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="Master of Business Administration" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  Overview </b> </center><br />
                                                    <div class="about__content">
                                                        
                                                        <p className='name'>The Department of Management at Aditya University focuses on challenging traditional business and management practices, encouraging students to question existing methods and think innovatively. They adopt a Case Study Approach, LPA (Learn, Practice, Apply) methodology, Activity Based Learning (ABL), industrial visits, guest lectures, seminars, business fests, and community initiatives to provide a holistic learning experience. Interactive sessions with entrepreneurs and business professionals offer insights into contemporary entrepreneurship, innovation, and practical business experience.
                                                        </p>
                                                        <p className='name'>The committed faculty members have authored numerous books, some of which are prescribed as textbooks by various universities, showcasing their expertise and dedication. The department offers Bachelor’s and Master’s programs in management along with a Dual Degree course aimed at grooming students for entry-level managerial positions or entrepreneurship.
                                                        </p>
                                                        <p className='name'>Furthermore, the Department of Management Studies is dedicated to challenging conventional wisdom and redefining business paradigms. Their curriculum emphasizes experiential learning through case studies, activity-based learning, and industry collaborations. Partnerships with industry leaders like SAP-ABAP and Pearson-Mepro provide students with access to cutting-edge resources and opportunities for professional development.
                                                        </p>
                                                        



                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  VISION : </b> <br />

                                                        <p className='name'>To become a centre of excellence in management education.</p>

                                                        <b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} /> MISSION:</b><br />
                                                        <p className='name'>
                                                            M1: By Providing quality education through innovative and latest pedagogical tools in management education.<br />
                                                            M2: By promoting research and innovative solutions to various management problems and serve the needs of industry and society.<br />
                                                            M3: Nurturing with Scientific Research in the field of Information Technology, enable students to involve in technological innovations.
                                                            <br />
                                                            M4: By collaborating with industries for effective teaching-learning process and to develop ethical future leaders capable of managing change and transformation in a globally competitive environment and to advance the theory and practice of management.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;