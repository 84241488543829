import React from 'react';
import Header from '../../../components/Header';
import FooterTwo from '../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../components/Breadcrumb/programsoffered';
import ScrollToTop from '../../../components/ScrollTop';
import { Link } from 'react-router-dom';
import "../../../components/Styles/pragram.css"
import point from '../../../assets/images/icons/image.png';
import point1 from '../../../assets/images/icons/image1.png';
import SideManu from './sidebar_programs';
import Brochure from "../../../components/Popup/Brochure/brochure";


const Eligibility = () => {
    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <StudyBreadcrumb pageTitle="UG Programs" />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <div class="container">
                                                    <br />
                                                    <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Programs Offered with Foreign Universities Collaboration</b>
                                                    <br />
                                                    <div class="about__content">

                                                        {/* <span class="name about__paragraph wow animate__fadeInUp" data-wow-duration="0.7s">B.Tech Programs- in collaboration with <b style={{ color: '#D2093C', fontSize: '17px' }}>RWTH Aachen University, Germany <br /> (QS Ranking:106, THE Ranking:90)</b></span>
                                                        <br />
                                                        <span class="name about__paragraph wow animate__fadeInUp" data-wow-duration="0.7s">Year 1<sup>st</sup>, 2<sup>nd</sup> & 3<sup>rd</sup>   	:	 Study at Aditya University</span><br />
                                                        <span class="name about__paragraph wow animate__fadeInUp" data-wow-duration="0.7s">Year 4<sup>th</sup>	            :	Transfer to Top German University</span><br />
                                                        <span class="name about__paragraph wow animate__fadeInUp text-center" data-wow-duration="0.7s"> (or)</span><br />
                                                        <span class="name about__paragraph wow animate__fadeInUp" data-wow-duration="0.7s">Year 1<sup>st</sup> & 2<sup>nd</sup>		:	Study at Aditya University</span> <br />
                                                        <span class="name about__paragraph wow animate__fadeInUp" data-wow-duration="0.7s">Year 3<sup>rd</sup> & 4<sup>th</sup>		:	Transfer to Top German University</span><br /><br /> */}

                                                        <span class="name"> B. Tech Programs in collaboration with RWTH Aachen University, Germany <b>(QS Ranking:99,THE Ranking :90)</b></span><br/>
                                                        
                                                        <span class="name">Students can opt for this Joint Degree Certification where stipulated credits are to be earned from Aditya University and RWTH Aachen University, Germany. </span><br/>
                                                        <span class="name"> The fee structure will be as per the respective university guidelines during the students' course of study.</span><br/><br/>

                                                        <div className="campus_sec1 row">
                                                            <div className="col-lg-6">
                                                                <div className="text-center">
                                                                    {/* <Link to="" className="more-about" target="blank" style={{ width: "100%" }}>  Brochure <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link> */}
                                                                    <Brochure />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="text-center">
                                                                    <Link to="https://apply.adityauniversity.in/" style={{ width: "100%" }} className="more-about" target="blank"> Apply Now !  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br />
                                                        <b style={{ color: "#D2093C", fontSize: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>B.Tech Programs Offered </b> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Agricultural Engineering</span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Civil Engineering</span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Civil Engineering - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with L & T </b></span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Computer Science and Engineering</span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Computer Science and Engineering - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft </b> </span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. – Artificial Intelligence & Machine Learning</span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. – Artificial Intelligence & Machine Learning - <b style={{ color: "#328afc", fontSize: "16px" }}>In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft </b> </span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. – (Computer Science and Engineering) - Data Science</span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. – (Computer Science and Engineering) - Data Science - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft </b> </span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Electronics and Communication Engineering</span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Electronics and Communication Engineering - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft </b> </span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Electronics and Electrical Engineering</span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Electronics and Electrical Engineering - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft </b> </span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Information Technology</span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Information Technology - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft </b> </span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Mechanical Engineering</span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Mechanical Engineering - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with CISCO, Red Hat, Oracle, AWS, Google & Microsoft </b> </span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Mining Engineering</span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Mining Engineering - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with L & T and Altair </b> </span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Petroleum Technology</span> <br />
                                                        <span className='name'> <img src={point} style={{ height: "25px" }} /> B.Tech. - Petroleum Technology - <b style={{ color: "#328afc", fontSize: "16px" }}> In Partnership with L & T, CISCO, Red Hat, Oracle, AWS, Google & Microsoft </b></span> <br />

                                                        <br />



                                                        <b style={{ color: "#D2093C", fontSize: "20px" }}>Eligibility Criteria for B.Tech Admission</b><br />
                                                        <p className='name'>
                                                            {/* <img src={point} style={{ height: "25px" }} /> The applicant should be a Resident Indian National.<br/> */}

                                                            <img src={point} style={{ height: "25px" }} />   Applicants must be at least 16 years old by December 31st of the admission year, with no upper age limit.<br />

                                                            <img src={point} style={{ height: "25px" }} />   Candidates have to be successful in ASAT (Aditya Scholastic Aptitude Test)/ JEE-Main/ State-Level Engineering Entrance Exams across India including EAPCET and Merit in Sports Activities.<br />

                                                            <img src={point} style={{ height: "25px" }} />   Candidates seeking UG Engineering admission must have completed or be appearing in 2024 for one of the specified qualifying examinations. Additionally, a minimum of 60% or equivalent CGPA is required for provisional candidature.<br />

                                                            <img src={point} style={{ height: "25px" }} />   Final examination of 10+2 system by State Boards, CBSE, or CISCE with core subjects as given below.</p><br />


                                                        <p className='name' style={{ padding: "14px", marginTop: "-69px" }}>
                                                            <img src={point1} style={{ height: "25px" }} />Students with Physics, Mathematics & Chemistry/ Computer Science/ Electronics/ Information Technology/ Biology/ Informatics Practices/ Biotechnology/Technical Vocational Subject/ Agriculture/ Engineering Graphics/ Business Studies/ Entrepreneurship are eligible for B.Tech Program in Electrical & Electronics Engineering, Electronics & Communications Engineering, Computer Science & Engineering, Information Technology, Artificial Intelligence & Machine Learning, Data Science.  <br />
                                                            <img src={point1} style={{ height: "25px" }} />Students with Physics, Chemistry and Mathematics (PCM) are eligible for B.Tech Programs in Civil Engineering, Mechanical Engineering, Petroleum Technology & Mining Engineering <br />

                                                            <img src={point1} style={{ height: "25px" }} />Students with Physics, Chemistry & Mathematics/ Biology/ Biotechnology/ Agriculture/ Agriculture Stream are eligible for B.Tech program in Agriculture Engineering. </p>

                                                        <p className='name' ><img src={point} style={{ height: "25px" }} />  Applicants who completed Class 12 (or equivalent) exams outside India or from a non-specified board must provide an AIU certificate confirming equivalence to Class 12, with grades/CGPA converted to percentage.</p>

                                                        <div className="campus_sec1 row">
                                                            <div className="col-lg-4">
                                                                <div className="text-center">
                                                                    <Link to="/fees" className="more-about" style={{ width: "100%" }}> Fees <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="text-center">
                                                                    <Link to="#" className="more-about" style={{ width: "100%" }}> Scholarships <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                                                    {/* <Link to="/scholarship" className="more-about" style={{ width: "100%" }}> Scholarships <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="text-center">
                                                                    <Link to="https://apply.adityauniversity.in/" className="more-about" target="blank" style={{ width: "100%" }}> Apply Now !  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ScrollToTop />
                </div>
            </div>
            <FooterTwo />
        </>
    );
}

export default Eligibility;