import React, { Component } from "react";
import Slider from "react-slick";
import Images1 from './images/1.jpg';
import Images2 from './images/2.jpg';
import Images3 from './images/3.jpg';
import Images4 from './images/4.jpg';
import SectionTitle from '../../components/SectionTitle'


function AutoPlay() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    cssEase: "linear",
    
  };
  
  return (
    <div className="slider-container">
      <SectionTitle Title="#2025 Placements" />
      
      <Slider {...settings}>
        <div>
          <img src={Images2} style={{border: '4px solid #16325a',margin:'10px'}}></img>
        </div>
        <div>
          <img src={Images1} style={{border: '4px solid #16325a',margin:'10px'}}></img>
        </div>
        <div>
          <img src={Images3} style={{border: '4px solid #16325a',margin:'10px'}}></img>
        </div>
        <div>
          <img src={Images4} style={{border: '4px solid #16325a',margin:'10px'}}></img>
        </div>
        
      </Slider>
    </div>
  );
}

export default AutoPlay;
