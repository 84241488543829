import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import FooterTwo from '../../components/Footer/FooterTwo';
import AboutBreadcrumb from '../../components/Breadcrumb/about';
import ScrollToTop from '../../components/ScrollTop';
import Loading from './loading.gif'; // Import your loading gif
const backendUrl = process.env.REACT_APP_DATABASEURL;
const Academiccouncils = () => {
    const [professor, setProfessor] = useState(null);

    useEffect(() => {
        const fetchProfessorData = async () => {
            try {
                const response = await axios.get(`${backendUrl}GetAcademicDetails`);
                setProfessor(response.data.academiccouncils);
            } catch (error) {
                console.error('Error fetching professor data:', error);
            }
        };
        fetchProfessorData();
    }, []); // Empty dependency array since department is not defined

    if (!professor) {
        return <div><center><img src={Loading} alt="Loading..." /></center></div>;
    }

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />

            <div className="react-wrapper">
                <div className="react-wrapper-inner">
                    <AboutBreadcrumb pageTitle="Academic council" />
                    <div className="col-md-12 col-sm-12 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                        <div className="container" style={{ width: '65%' }}>
                            <div className="table-responsive table-striped">
                                <table className="table responsive">
                                    <thead>
                                        <tr>
                                            <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center" }}>S.No</th>
                                            <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center", width: '25%' }}>Name</th>
                                            <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center" }}>Designation</th>
                                            <th style={{ backgroundColor: "#3166c7", color: '#fff', textAlign: "center", width: '26%' }}>Position in the committee</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {professor.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{index + 1}</td>
                                                <td style={{ textAlign: 'left', verticalAlign: 'middle' }}>{item.name}</td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.designation}</td>
                                                <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{item.position}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                <ScrollToTop />
                </div>
            </div>
        <FooterTwo />
        </>
    );
}

export default Academiccouncils;
