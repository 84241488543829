import React from 'react';
import Header from '../../../../../components/Header';
import FooterTwo from '../../../../../components/Footer/FooterTwo';
import StudyBreadcrumb from '../../../../../components/Breadcrumb/academics';
import ScrollToTop from '../../../../../components/ScrollTop';

// import { Link } from 'react-router-dom';
import "../../../../../components/Styles/pragram.css"
import point from '../../../../../assets/images/icons/image.png';
import point1 from '../../../../../assets/images/icons/image1.png';

// import Enquire from '../../home/Enquire';
import SideManu from './sidebar_ece';
import Banner from './banner';
import ECEPopUp from "../../../../../components/Popup/ECE/ECEPopUp";






const CEview = () => {

    return (
        <>
            <Header
                parentMenu='page'
                menuCategoryEnable='enable'
            />
            <div class="react-wrapper">
                <div class="react-wrapper-inner">
                    <Banner />
                    <ECEPopUp />
                    <div className="high_quality-section pt---110 pb---120">
                        <div className="container">
                            <div className="react__title__section-all">
                                <div class="research" id="">
                                    <div class="container">
                                        <div class="row m-0">
                                            <SideManu />
                                            <div class="col-md-1  col-sm-1 col-2"></div>
                                            <div class="col-md-8  col-sm-8 col-12" style={{ backgroundColor: "#f8f9fa" }}>
                                                <br />
                                                <div class="container">
                                                    <center><b style={{ color: "#D2093C", fontSize: "20px", textAlign: "center" }}><img src={point} style={{ height: "25px" }} />  B-Tech </b> </center><br />
                                                    <div class="about__content">
                                                        <p className='name'>The Department of Electronics and Communication Engineering (ECE) was established with the inception of the college in the year 2001 with an intake of 60 students and now it has been expanded to an intake of 240 students. The department has dedicated faculty and well-equipped laboratories like Electronic Devices and Circuits lab, Microwave Engineering and Optical Communication Lab, Digital Signal Processing lab, Project lab, etc, to provide a platform for practical aspects of learning</p>

                                                        <p className='name'>The Department of ECE has an IETE student forum, under which the department organizes professional activities including guest lectures, workshops, and seminars, technical festivals SPARK under the umbrella of VEDA to enrich the knowledge of students. For better placements, we have a strong technical team (Technical hub) to train our students in coding practices and problem-solving skills. </p>

                                                        <p className='name'><p className='name'>The Department of ECE has an IETE student forum, under which the department organizes professional activities including guest lectures, workshops, and seminars, technical festivals SPARK under the umbrella of VEDA to enrich the knowledge of students. For better placements, we have a strong technical team (Technical hub) to train our students in coding practices and problem-solving skills. </p> </p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Electronics and Communication Engineering <br />
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Electronics and Communication Engineering with minor Specialization in
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in VLSI </span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Embedded Systems</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Signal Processing and Communication Technology</span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Space Technology </span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Technology for Digital Resilience   <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b>
                                                            </span> <br />
                                                            <span className='name'> <img src={point1} style={{ height: "25px" }} /> Minor Stream in Smart Infrastructure (Electronics) <b style={{ color: "#328afc", fontSize: "17px" }}> (Industry Integrated Program - L&T) </b></span> <br />
                                                        </p>
                                                        <p className='name'>
                                                            <img src={point} style={{ height: "25px" }} />  B.Tech - Electronics and Communication Engineering with
                                                        </p>
                                                        <p className='name' style={{ padding: "14px", marginTop: "-29px" }}>
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Civil  Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Electrical and Electronics Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mechanical Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Computer Science and Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Data Science<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Artificial Intelligence and Machine Learning<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Petroleum Technology<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Mining Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Agricultural Engineering<br />
                                                            <img src={point1} style={{ height: "25px" }} /> Minor degree in Entrepreneurship Development and Incubation<br />
                                                        </p>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTop />
            </div>
            <FooterTwo />

        </>
    );
}

export default CEview;