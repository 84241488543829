import React, { useState } from 'react';
import { jsPDF } from 'jspdf';

function PDFView() {
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const fetchDataAndGeneratePDF = async () => {
    setLoading(true);

    try {
      const response = await fetch(`https://adityauniversity.in:4001/${inputValue}`);
      if (!response.ok) {
        alert("No data");
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data);

      const doc = new jsPDF();
      const pageHeight = doc.internal.pageSize.getHeight();
      const margin = 20;
      const maxLineWidth = doc.internal.pageSize.getWidth() - margin * 2;
      const lineHeight = 10;
      let y = margin;

      const addText = (text, x) => {
        const lines = doc.splitTextToSize(text, maxLineWidth);
        lines.forEach(line => {
          if (y + lineHeight > pageHeight - margin) {
            doc.addPage();
            y = margin;
          }
          doc.text(line, x, y);
          y += lineHeight;
        });
      };

      doc.setDrawColor(0);
      doc.setLineWidth(1);
      doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, 'S');

      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width;
      const textWidth = doc.getStringUnitWidth('Application for Admission into Ph.D.') * doc.internal.getFontSize() / doc.internal.scaleFactor;
      const x = (pageWidth - textWidth) / 2;

      const imgWidth_1 = 60;
      const imgHeight_1 = 20;
      const imgX_1 = 75;
      const imgY_1 = 5;

      // Draw a white rectangle behind the image
      doc.setFillColor(255, 255, 255); // Set the fill color to white
      doc.rect(imgX_1, imgY_1, imgWidth_1, imgHeight_1, 'F'); // Draw the rectangle with 'F' option to fill it

      const imageData_1 = await getImageData('https://adityauniversity.in/static/media/au_logo_new.b993a20be611bd3711d9.png');
      doc.addImage(imageData_1, 'JPEG', imgX_1, imgY_1, imgWidth_1, imgHeight_1);

      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text('Personal Details', 10, 30);
      doc.setFontSize(8);
      doc.text(`Application ID : ${data.Application_id}`, 10, 35);

      const imgWidth = 40;
      const imgHeight = 40;
      const imgX = 150;
      const imgY = 30;
      const imageData = await getImageData(`https://adityauniversity.in:4001/form/${data.Photo}`);
      doc.addImage(imageData, 'JPEG', imgX, imgY, imgWidth, imgHeight);

      const toTitleCase = (str) => {
        return str.replace(/\w\S*/g, (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      };

      doc.text(`Name of Applicant : ${toTitleCase(data.Name_of_Applicant)}`, 10, 40);
      doc.text(`Father Name : ${toTitleCase(data.Name_of_Father)}`, 10, 45);
      doc.text(`Gender : ${toTitleCase(data.Gender)}`, 10, 50);
      doc.text(`Category : ${toTitleCase(data.Category)}`, 10, 55);
      doc.text(`Date of Birth : ${data.Date_Of_Birth}`, 10, 60);
      doc.text(`Email ID : ${data.Email}`, 10, 65);
      doc.text(`Contact No : ${data.ContactNo}`, 10, 70);
      doc.text(`Nationality : ${toTitleCase(data.Nationality)}`, 10, 75);
      doc.text(`Address : ${toTitleCase(data.Address)}`, 10, 80);

      doc.setFontSize(10);
      doc.text('Academic Details', 10, 95);
      doc.setFontSize(8);
      doc.text(`SSC/ X Standard : ${toTitleCase(data.SSC_X_Standard_Place)}`, 10, 100);
      doc.text(`Board : ${toTitleCase(data.SSC_X_Standard_Board)}`, 10, 105);
      doc.text(`Year : ${data.SSC_X_Standard_Year}`, 10, 110);
      doc.text(`Percentage : ${data.SSC_X_Standard_Percentage}`, 10, 115);

      doc.text(`HSC / XII Standard  : ${toTitleCase(data.HSC_XII_Standard_Place)}`, 110, 100);
      doc.text(`Board : ${toTitleCase(data.HSC_XII_Standard_Board)}`, 110, 105);
      doc.text(`Year : ${data.HSC_XII_Standard_Year}`, 110, 110);
      doc.text(`Percentage : ${data.HSC_XII_Standard_Percentage}`, 110, 115);

      doc.text(`Bachelors' Degree : ${toTitleCase(data.Bachelors_Degree_Place)}`, 10, 125);
      doc.text(`Board : ${toTitleCase(data.Bachelors_Degree_Board)}`, 10, 130);
      doc.text(`Year : ${data.Bachelors_Degree_Year}`, 10, 135);
      doc.text(`Percentage : ${data.Bachelors_Degree_Percentage}`, 10, 140);

      doc.text(`Masters' Degree  : ${toTitleCase(data.Masters_Degree_Place)}`, 110, 125);
      doc.text(`Board : ${toTitleCase(data.Masters_Degree_Board)}`, 110, 130);
      doc.text(`Year : ${data.Masters_Degree_Year}`, 110, 135);
      doc.text(`Percentage : ${data.Masters_Degree_Percentage}`, 110, 140);

      doc.text(`Category File Upload: ${toTitleCase(data.CCertificate ? 'Yes' : 'No')}`, 10, 150);
      doc.text(`Certificates File Upload: ${toTitleCase(data.Academic_Certificates ? 'Yes' : 'No')}`, 70, 150);
      doc.text(`NET/SET/GATE Score File Upload: ${toTitleCase(data.NET_SET_GATE_Score ? 'Yes' : 'No')}`, 140, 150);
      doc.text(`Publications File Upload: ${toTitleCase(data.First_Page_Publication_file ? 'Yes' : 'No')}`, 10, 155);
      doc.text(`Professional Experience File Upload: ${toTitleCase(data.Professional_Experience ? 'Yes' : 'No')}`, 70, 155);
      doc.text(`Research Plan File Upload: ${toTitleCase(data.Research_plan_file ? 'Yes' : 'No')}`, 140, 155);

      doc.setFontSize(10);
      doc.text('Publications', 10, 170);
      doc.setFontSize(8);

      let startY = 175;

      if (data.Publication && data.Publication.length > 0) {
        doc.text('data available.', 10, 175);
       } else {
        doc.text('No data .', 10, 175);
      }

      doc.setFontSize(10);
      doc.text('Employee Experience', 95, 170);
      doc.setFontSize(8);

      if (data.Employee_Experience && data.Employee_Experience.length > 0) {
        doc.text('data available.', 95, 175);
      } else {
        doc.text('No data .', 95, 175);
      }

      doc.text(`Research topic : ${toTitleCase(data.Research_Plan)}`, 10, 185);
      doc.text(`Other particulars : ${toTitleCase(data.Other_Particulars)}`, 10, 195);

      doc.text(`Interested : ${toTitleCase(data.Interested)}`, 10,205 );
      doc.text(`Discipline Applying for : ${toTitleCase(data.department !== '' ? data.department : '--')}`, 75,205 );
      doc.text(`NET/SET/GATE Score : ${toTitleCase(data.net_score !== '' ? data.net_score : '--')}`, 150,205 );
      doc.text(`Scopus URL : ${toTitleCase(data.Scopus_URL !== '' ? data.Scopus_URL : '--')}`, 10, 210);
      doc.text(`Whether the applicant has published any Journal papers / Books / Book Chapters : ${toTitleCase(data.published)}`, 10, 215);


      doc.text(`Payment : ${toTitleCase(data.payment_status !== '' ? data.payment_status : '--')}`, 10, 240);

      doc.text('Declaration', 160, 285);
      doc.text(`${toTitleCase(data.Signature)}`, 160, 290);

      doc.save(`${inputValue}.pdf`);
    } catch (error) {
      console.error('Error fetching data or generating PDF:', error);
    } finally {
      setLoading(false);
    }
  };

  const getImageData = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);

      if (!response.ok) {
        throw new Error('Failed to fetch image');
      }

      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error fetching image:', error);
      throw error;
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <center><input
        type="text"
        placeholder="Application ID"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        style={{ marginRight: '10px' ,width:'107px'}}
      required />
      <button style={{color: '#f2f4f9',padding: '2px',background: '#00306e',borderRadius:'11px',fontSize: '13px'}} onClick={fetchDataAndGeneratePDF} disabled={loading}>
        {loading ? 'Generating...' : 'Download'}
      </button>
      </center>
    </div>
  );
}

export default PDFView;
