import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    return (
        <div className="col-md-3 col-sm-3 col-0">
            <div className="side-navbar">
                <nav className="navbar">
                    <ul className="navbar-nav">
                        <li className="navbar-title text-center">
                            <a className="navbar-brand text-light1" style={{ padding: '12px 124px' }} href="#">Schools </a>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/academics' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/academics">Academics Overview</Link>
                        </li>
                        <li className={`nav-item dropdown ${window.location.pathname.startsWith('/school_of_engineering') ? 'active' : ''}`}>
                            <Link className="nav-link" to="/school_of_engineering">School of Engineering</Link>
                            {/* <div className="dropdown-content">
                                <Link to="/school_of_engineering/home">Home</Link>
                                <Link to="/school_of_engineering/schools">Schools</Link>
                            </div> */}
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/school_of_business' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/school_of_business">School of Business</Link>
                        </li>
                        <li className={`nav-item ${window.location.pathname === '/holidays' ? 'active' : ''}`}>
                            <Link className="nav-link" to="/holidays">Holidays</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Sidebar;
